import { Component, Inject } from "@angular/core";
import { PymService } from "@viewer/core/pym/pym.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PymResponse, PymSource } from "@orion2/models/pym.models";
import { ExcelService } from "@viewer/core/excel/excel.service";

export interface PymModalDataSource {
  source: PymSource[];
  filename: string;
}

@Component({
  selector: "o-pym-modal",
  templateUrl: "./pym-modal.component.html",
  styleUrls: ["./pym-modal.component.scss"]
})
export class PymModalComponent {
  depth = 3;
  main = true;
  conditional = true;
  general = false;
  public isLoading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PymModalDataSource,
    private pymService: PymService,
    private excelService: ExcelService,
    private dialog: MatDialogRef<PymModalComponent>
  ) {}

  close(response = false): void {
    this.dialog.close(response);
  }

  submit(): Promise<void> {
    this.isLoading = true;
    const level = `${this.depth}`;
    const infoType = [];
    if (this.main) {
      infoType.push("main");
    }
    if (this.conditional) {
      infoType.push("conditional");
    }
    if (this.general) {
      infoType.push("general");
    }
    return this.pymService
      .extractMaintenanceTree(level, infoType, this.data.source)
      .then(
        (pymResponse: PymResponse) =>
          pymResponse && this.excelService.exportPymToExcel(pymResponse, this.data.filename)
      )
      .then(() => {
        this.close(true);
        this.isLoading = false;
      });
  }

  isInvalid(): boolean {
    return !(this.main || this.conditional || this.general);
  }
}
