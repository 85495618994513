import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import {
  LoggerService,
  PouchService,
  Store,
  ConfService,
  SearchService,
  ViewerMessageService
} from "@viewer/core";
import { runInAction } from "mobx";
import { HomeResolver } from "@viewer/home/resolver/home.resolver";
import { PubDoc } from "libs/models/couch.models";
import { environment } from "@viewer-env/environment";
import { LocalStorageBackend } from "@openid/appauth";
import { User } from "@orion2/auth/user";
import { guestUser } from "@orion2/auth/role.interfaces";
import { TranslateService } from "@ngx-translate/core";
import { TransfertTiService } from "@viewer/core/transfert-ti/transfert-ti.service";
import { PlayerService } from "@viewer/core/player/player.service";
import { BasepubUserService } from "@viewer/core/basepub/basepub-user.service";
import { HttpMethod } from "libs/http/abstractHttp.service";
import packageJSON from "@package-json";
import { FullHttpService } from "libs/http/fullHttp.service";
import { PubMeta } from "@viewer/core/pouchdb/caller";
import { ProductsService } from "@viewer/home/service/products.service";
import { HomeRoute } from "@viewer/home/models";
import { DBConnexionType } from "@viewer/core/pouchdb/types";

@Injectable()
export class PubAccessResolver implements Resolve<boolean> {
  private storage = new LocalStorageBackend();
  private resolvePromise: Promise<boolean>;

  constructor(
    private router: Router,
    private location: Location,
    private homeResolver: HomeResolver,
    private productsService: ProductsService,
    private pouchService: PouchService,
    private logger: LoggerService,
    private store: Store,
    private translate: TranslateService,
    private messageService: ViewerMessageService,
    private transfertTiService: TransfertTiService,
    private playerService: PlayerService,
    private httpService: FullHttpService,
    private confService: ConfService,
    private basepubUserService: BasepubUserService,
    private searchService: SearchService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<boolean> {
    if (!this.resolvePromise) {
      // Because the resolver can trigger navigation on the pub route, it may lead to infinite loops
      // where navigation triggers the resolver again, which then triggers navigation, and so on.
      // To avoid this, we cache the resolve promise so that access to a pub can only be resolved once.
      this.resolvePromise = this.getResolvePromise(route);
    }

    return this.resolvePromise.finally(() => {
      this.resolvePromise = undefined;
    });
  }

  private getResolvePromise(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.init(route)
      .then(() => {
        const pubId = route.paramMap.get("pubId");
        // Resolver is on /:pubId, needs to get child param to get /:pubRev
        const pubRev = route.firstChild?.paramMap.get("pubRev") || "";

        return this.getPubInfo(pubId, pubRev).then((pubInfo: PubDoc) => {
          // Store state should not be changed before the call of "updateStore"
          if (!pubInfo) {
            // If we are in browser and we are not logged, there will be a redirection to the login page '/login' so we want
            // to prevent our fallback redirection to '/local'
            if (
              environment.platform !== "browser" ||
              !this.confService.useAuth ||
              this.store.isLoggedIn
            ) {
              this.router.navigateByUrl(HomeRoute.LOCAL);
              this.messageService.error(`${pubId} ${pubRev} is not available.`);
            }
            return false;
          }

          // we need to check the last value form local storage, if the user is logged in, then we get his last role
          // otherwise guestUser is set.
          this.getUserRoleFromLocalStorage();
          // We need to call switchPublication first in order to control the state of the callers.
          // For example as ApplicabilityService react to store.publicationID it is important to
          // have applicCaller set with the new pubId before using the caller.
          return this.pouchService
            .switchPublication(pubInfo)
            .then(() => this.checkPlayer(pubInfo))
            .then(() => this.checkTransferTi(pubInfo))
            .then(() => {
              this.postTracking(pubInfo);
              this.updateStore(pubInfo);
              return true;
            });
        });
      })
      .catch(e => {
        this.logger.error(e);
        return false;
      });
  }

  private getPubInfo(pubId: string, pubRev: string): Promise<PubDoc> {
    let newUrl = this.location.path();
    let printWarning = false;
    let searchLastTechRev = false;
    let searchNonPublished = false;
    this.searchService.lastSearchPage = 0;

    const pubRoute = `/${pubId}/${pubRev}`;

    // If 'latest' is given after the 'pubRev' in URL, we need to find the latest technical revision of the same major revision of 'pubRev',
    //  so we set this to true to tell the pubCaller to find it and remove the 'latest' from the future URL
    if (newUrl.includes(`${pubRoute}/latest`)) {
      searchLastTechRev = true;
      newUrl = newUrl.replace(`${pubRoute}/latest`, pubRoute);
    }

    // If 'preview' is given after the 'pubRev' in URL, we comme from the admin to preview a potentially non-published IETP, so we set this
    //  variable to true to tell the pubCaller to search also for non-published pubs, and remove 'preview' from the URL
    if (newUrl.includes(`${pubRoute}/preview`)) {
      searchNonPublished = true;
      newUrl = newUrl.replace(`${pubRoute}/preview`, pubRoute);
    }

    // We resolve the pubId independently from the type of the given pubId (occCode, pubId, ...)
    return this.pouchService.pubCaller
      .resolvePubId(pubId, pubRev, searchLastTechRev)
      .then(async doc => {
        // In case of 'preview' mode we need to potentially look for non-published pubs. But as preview mode has a change in the URL we
        //  will pass two times, and we need to have possibility in both passages to query non-published pubs, this is why at first passage
        //  we allow access because 'preview' is in URL and we grant access for the next passage by setting a store variable to true.
        if (searchNonPublished || this.store.allowPreviewAccess) {
          // We don't need to look for the non-published pubs if we have resolved a published one
          if (!doc) {
            doc = await this.pouchService.pubCaller.resolvePubId(
              pubId,
              pubRev,
              searchLastTechRev,
              true
            );
          }

          // The first passage will grant access for the next one (false => true).
          // The second one wil use it and lock the access again (true => false).
          this.store.allowPreviewAccess = !this.store.allowPreviewAccess;
        }

        if (!doc) {
          return undefined;
        }

        // If we need to change the URL we prepare it
        // TODO: When we will use alias in url, change 'doc.packageId' into 'doc.aliasPackageId' in the condition and in the URL replacement
        if (doc.packageId !== pubId || doc.revision !== pubRev) {
          if (pubRev !== doc.revision && pubRev !== "latest" && !searchLastTechRev) {
            printWarning = true;
          }
          if (newUrl.endsWith(`/${pubId}`)) {
            newUrl += `/${doc.revision}`;
          } else if (newUrl.endsWith(`/${pubRev}`)) {
            newUrl = newUrl.replace(`/${pubRev}`, `/${doc.revision}`);
          } else {
            newUrl = newUrl.replace(`/${pubRev}/`, `/${doc.revision}/`);
          }
          newUrl = newUrl.replace(`/${pubId}`, `/${doc.packageId}`);
        }

        // Then we really set the new identifiers
        pubId = doc.packageId;
        pubRev = doc.revision;

        if (newUrl !== this.location.path()) {
          // SPEC: We don't need to update URL on Cordova since the app works perfectly with occCode instead of pubId
          if (environment.platform !== "cordova") {
            // In case of a direct link, we need to provide also the rest of the URL with only the new pubId and pubRev
            this.router.navigateByUrl(newUrl);
          }
          if (printWarning) {
            this.messageService.warning(
              `Could not find revision ${pubRev}. Accessed revision ${doc.revision}.`
            );
          }
        }

        const isUserLoggedIn = this.store.isLoggedIn && this.confService.useAuth;

        // Here we use productsService instead of PubCaller
        // as productsService already has the publications listed.
        let pub = this.productsService.getPubDoc(`${pubId}__${pubRev}`);
        if (pub) {
          return isUserLoggedIn && !pub.isPackagePDF ? this.checkUserDbs(pub).then(() => pub) : pub;
        }

        // If we navigate directly to the pub route without displaying the home page
        // then productsService return undefined and we have to use pubCaller.getPub
        pub = await this.pouchService.pubCaller.getPub(`${pubId}__${pubRev}`);
        if (pub) {
          // If the user does not go through the "store",
          // the map of the latest available version is not up to date.
          const targetDb = isUserLoggedIn ? DBConnexionType.REMOTE : DBConnexionType.LOCAL;
          return this.pouchService.pubCaller
            .getPubsMeta(["all"], targetDb)
            .then((pubs: PubMeta[]) => this.productsService.storeLastRevisions(pubs))
            .then(() =>
              isUserLoggedIn && !pub.isPackagePDF
                ? this.checkUserDbs(pub).then(() => this.productsService.enrichPubDoc(pub))
                : this.productsService.enrichPubDoc(pub)
            );
        }

        return undefined;
      })
      .catch(err => {
        console.error(err);
        return undefined;
      });
  }

  private checkTransferTi(pub: PubDoc): Promise<boolean> {
    // SPEC: Transfer is available only when user is logged in and the pub has synchronisation capability
    if (pub?.capabilities?.sync && this.store.isLoggedIn && this.confService.useAuth) {
      return this.transfertTiService.checkUserHasPending(pub).catch(error => {
        console.error("transferTocItemsFromPending error: ", error);
        this.messageService.error(`${this.translate.instant("transfert-ti.message.error")}`);
        return Promise.reject(false);
      });
    }
    return Promise.resolve(true);
  }

  /**
   * Function that calls HomeResolver if pouch service is not initiated yet
   *
   * @param route
   */
  private init(route: ActivatedRouteSnapshot): Promise<void> {
    if (!this.store.pouchReady) {
      return this.homeResolver.resolve(route);
    }
    // To switch publication on deeplinking we need to set search not ready
    runInAction(() => {
      this.store.setOfflineSearchNotReady();
    });
    return Promise.resolve();
  }

  private updateStore(pubInfo: PubDoc): void {
    runInAction(() => {
      this.store.publicationID = pubInfo.packageId;
      this.store.publicationRevision = pubInfo.revision;
      this.store.pubInfo = pubInfo;
      this.store.currentDMC = undefined;
    });
  }

  /**
   * Check user DBs are created
   *
   * @param pub
   */
  private checkUserDbs(pub: PubDoc): Promise<boolean> {
    // SPEC: If pub is offline, we still need to check remote user DBs
    return environment.authService.checkUserDbs(pub.occurrenceCode).then((ok: boolean) => {
      if (!ok) {
        console.error(
          this.translate.instant("userDbs.check.error", {
            pubName: pub.verbatimText
          })
        );
        this.messageService.error(
          this.translate.instant("userDbs.check.error", {
            pubName: pub.verbatimText
          })
        );
        return false;
      }
      return true;
    });
  }

  private checkPlayer(pub: PubDoc): Promise<void> {
    // SPEC: Should only check if cordova, pub has 3D capability and user is logged in
    if (
      window.hasOwnProperty("cordova") &&
      pub?.capabilities?.player3D &&
      this.store.playerSettings?.["cordova"] &&
      this.store.isLoggedIn
    ) {
      return this.playerService
        .checkPlayer(packageJSON.playerVersion, () =>
          this.downloadPlayer(packageJSON.playerVersion)
        )
        .catch(err => {
          // SPEC: We should not block the use of the pub if the player is not downloaded
          console.error(err);
          this.messageService.error(
            `${this.translate.instant("download.player.error", {
              version: packageJSON.playerVersion
            })} ${this.translate.instant("session.no3D")}`
          );
        });
    }
    return Promise.resolve();
  }

  private downloadPlayer(version: string): Promise<Blob> {
    return this.httpService.sendRequest<Blob>(
      HttpMethod.GET,
      `db_secure/player_3d/player_lib/DS_${version}.zip`,
      { responseType: "blob" }
    );
  }

  private getUserRoleFromLocalStorage(): Promise<void> {
    return this.storage
      .getItem("user_role")
      .then((localUserRole: string) => {
        if (localUserRole) {
          const user = new User(JSON.parse(localUserRole));
          runInAction(() => {
            this.store.user = user;
          });
        } else {
          console.error("User role not found");
        }
      })
      .catch(_error => {
        this.store.user = new User(guestUser);
      });
  }

  private postTracking(pub: PubDoc): void {
    if (this.confService.conf.hasBP2 && this.store.isLoggedIn) {
      this.basepubUserService.postTracking({
        objectId: pub.occurrenceCode,
        objectType: "productRevision",
        date: new Date(),
        type: "consult"
      });
    }
  }
}
