import { OidcConfiguration } from "@orion2/auth/oidc.conf";
import { RedirectRequestHandler } from "@openid/appauth/built/redirect_based_handler";
import { AuthorizationRequestResponse } from "@openid/appauth/built/authorization_request_handler";
import {
  AuthorizationRequest,
  AuthorizationRequestJson
} from "@openid/appauth/built/authorization_request";
import { AuthorizationServiceConfiguration } from "@openid/appauth/built/authorization_service_configuration";
import { Crypto } from "@openid/appauth/built/crypto_utils";
import { log } from "@openid/appauth/built/logger";
import {
  AuthorizationResponse,
  AuthorizationError
} from "@openid/appauth/built/authorization_response";
import { StorageBackend } from "@openid/appauth/built/storage";
import { isUrl } from "@viewer/shared-module/helper.utils";
import {
  RevokeTokenRequest,
  RevokeTokenRequestJson
} from "@openid/appauth/built/revoke_token_request";
import { EndSessionRequest } from "libs/auth/src/end.session.request";

/** key in local storage which represents the current authorization request. */
const AUTHORIZATION_REQUEST_HANDLE_KEY = "appauth_current_authorization_request";

/** key for authorization request. */
const authorizationRequestKey = (handle: string) => `${handle}_appauth_authorization_request`;

/** key for authorization service configuration */
const authorizationServiceConfigurationKey = (handle: string) =>
  `${handle}_appauth_authorization_service_configuration`;

const DefaultPopupFeatures = "location=no,toolbar=no,width=500,height=500,left=100,top=100;";

const DefaultPopupTarget = "_blank";

export class BrowserAuthorizationRequestHandler extends RedirectRequestHandler {
  public config: OidcConfiguration;

  public _promise: Promise<{}>;
  public _resolve: (value?: {} | PromiseLike<{}>) => void;
  public _reject: (reason?: Error | string) => void;

  protected loginUri: URL;
  protected errorOnClose = true;
  protected redirect_uri: string;

  protected readonly EVENT_TYPE: string = "message";
  protected _popup: Window;

  private authorizationCallbackBind: () => {};
  private features: string;
  private target: string;
  private currentResponse: AuthorizationRequestResponse;

  constructor(storage?: StorageBackend, crypto?: Crypto) {
    super(storage, undefined, {} as Location, crypto);
    this._promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });

    this.target = DefaultPopupTarget;
    this.features = DefaultPopupFeatures;
    this.authorizationCallbackBind = this._authorizationCallback.bind(this);
  }

  get promise() {
    return this._promise
      .then(() => this.completeAuthorizationRequest())
      .catch(err => {
        console.log("perform", err);
        throw err;
      });
  }

  resetPromise() {
    this._promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  performAuthorizationRequest(
    configuration: AuthorizationServiceConfiguration,
    request: AuthorizationRequest
  ): Promise<void | AuthorizationRequestResponse> {
    this.redirect_uri = request.redirectUri;
    const handle = this.crypto.generateRandom(10);
    // before you make request, persist all request related data in local storage.
    const persisted = Promise.all([
      this.storageBackend.setItem(AUTHORIZATION_REQUEST_HANDLE_KEY, handle),
      // Calling toJson() adds in the code & challenge when possible
      request
        .toJson()
        .then(result =>
          this.storageBackend.setItem(authorizationRequestKey(handle), JSON.stringify(result))
        ),
      this.storageBackend.setItem(
        authorizationServiceConfigurationKey(handle),
        JSON.stringify(configuration.toJson())
      )
    ]);

    return persisted
      .then(() => this.requestAuthorization(configuration, request))
      .then(() => this.completeAuthorizationRequest())
      .catch(err => {
        console.log("perform", err);
        throw err;
      });
  }

  public performEndSessionRequest(
    configuration: AuthorizationServiceConfiguration,
    request: EndSessionRequest
  ): Promise<void> {
    // SPEC: For Airbus pingfederate we should call the /ext path for endsession in order to be redirected to the proxy
    // The url should be like https://idp-val.airbushelicopters.com/ext/startSLO.ping
    const endSessionEndpoint = /https:\/\/idp(-\w*)?.airbushelicopters.com/g.test(
      configuration.endSessionEndpoint
    )
      ? configuration.endSessionEndpoint.replace("/idp/", "/ext/")
      : configuration.endSessionEndpoint;

    return this.navigate(`${endSessionEndpoint}?${request.getQueryParam()}`).then(() => {});
  }

  public setConfig(config: OidcConfiguration): void {
    this.config = config;
  }

  protected requestAuthorization(
    configuration: AuthorizationServiceConfiguration,
    request: AuthorizationRequest
  ): Promise<void | AuthorizationRequestResponse> {
    // make the redirect request
    const url = this.buildRequestUrl(configuration, request);
    window.removeEventListener(this.EVENT_TYPE, this.authorizationCallbackBind);
    window.addEventListener(this.EVENT_TYPE, this.authorizationCallbackBind);
    return this.navigate(url);
  }

  protected navigate(url: string): Promise<AuthorizationRequestResponse> {
    if (this.config.popup) {
      const winRect = window.document.querySelector("html").getBoundingClientRect();
      this.features = `location=no,toolbar=no,width=500,height=500,left=${
        winRect.left + (winRect.width - 500) / 2
      },top=${winRect.top + (winRect.height + 500) / 2};`;

      this._popup = window.open("", this.target, this.features);

      if (!this._popup) {
        this._reject("PopupWindow.navigate: Error opening popup window");
      } else {
        this._popup.window.location.assign(url);
        this._popup.focus();
      }
    } else {
      window.dispatchEvent(new CustomEvent("loginRequest", { detail: { url } }));
    }

    return this.promise;
  }

  protected _authorizationCallback(event, keepOpen = false) {
    if (!(event instanceof MessageEvent || event.url === event.data)) {
      return;
    }
    this.errorOnClose = false;

    if (event.type !== this.EVENT_TYPE) {
      return;
    }

    if (typeof event.data !== "string" || !isUrl(event.data)) {
      return;
    }
    this._cleanup(keepOpen);

    this.loginUri = new URL(event.data);

    // TODO DG Handle errors in callback url
    // see https://tools.ietf.org/id/draft-ietf-oauth-v2-12.html#anchor18 section 4.1.2
    if (this.loginUri) {
      window.dispatchEvent(new CustomEvent("authRequestEnd"));
      this._success(this.loginUri);
    } else {
      log("PopupWindow.callback: Invalid response from popup");
      this._error("Invalid response from popup");
    }
  }

  protected _success(data) {
    this._resolve(data);
  }

  protected _error(message) {
    log("PopupWindow.error: ", message);

    this._cleanup();
    this._reject(new Error(message));
  }

  protected _cleanup(keepOpen = false) {
    if (this._popup && !keepOpen) {
      this._popup.close();
    }
    this._popup = null;
  }

  protected handleClose() {
    if (this.errorOnClose) {
      this.errorOnClose = false;
      this._error("Invalid response from popup");
    }
  }

  protected processRedirect(request: AuthorizationRequest, handle: string) {
    // check redirect_uri and state
    const state: string | undefined = this.loginUri.searchParams.get("state");
    const code: string | undefined = this.loginUri.searchParams.get("code");
    const error: string | undefined = this.loginUri.searchParams.get("error");
    const shouldNotify = state === request.state;
    let authorizationResponse: AuthorizationResponse | null = null;
    let authorizationError: AuthorizationError | null = null;
    if (shouldNotify) {
      if (error) {
        // get additional optional info.
        const errorUri = this.loginUri.searchParams.get("error_uri");
        const errorDescription = this.loginUri.searchParams.get("error_description");
        authorizationError = new AuthorizationError({
          error,
          error_description: errorDescription,
          error_uri: errorUri,
          state
        });
      } else {
        authorizationResponse = new AuthorizationResponse({
          code,
          state
        });
      }
      // cleanup state
      return Promise.all([
        this.storageBackend.removeItem(AUTHORIZATION_REQUEST_HANDLE_KEY),
        this.storageBackend.removeItem(authorizationRequestKey(handle)),
        this.storageBackend.removeItem(authorizationServiceConfigurationKey(handle))
      ]).then(() => {
        this.loginUri = undefined;
        this.currentResponse = {
          request,
          response: authorizationResponse,
          error: authorizationError
        } as AuthorizationRequestResponse;
        return this.currentResponse;
      });
    } else {
      log("Mismatched request (state and request_uri) dont match.");
      return Promise.resolve(null);
    }
  }

  protected processLogout(request: RevokeTokenRequest, handle: string): Promise<void> {
    // check redirect_uri and state
    // cleanup state
    this.loginUri = undefined;
    this.resetPromise();
    return Promise.all([
      this.storageBackend.removeItem(AUTHORIZATION_REQUEST_HANDLE_KEY),
      this.storageBackend.removeItem(authorizationRequestKey(handle)),
      this.storageBackend.removeItem(authorizationServiceConfigurationKey(handle))
    ]).then(() => {});
  }

  protected completeRevokationRequest(): Promise<void> {
    return this.storageBackend.getItem(AUTHORIZATION_REQUEST_HANDLE_KEY).then((handle: string) =>
      // we have a pending request.
      // fetch authorization request, and check state
      this.storageBackend
        .getItem(authorizationRequestKey(handle))
        .then((result: string) => JSON.parse(result))
        .then((json: RevokeTokenRequestJson) => new RevokeTokenRequest(json))
        .then((request: RevokeTokenRequest) => this.processLogout(request, handle))
    );
  }

  /**
   * Attempts to introspect the contents of storage backend and completes the
   * request.
   *
   * LABEL : orionSLO
   */
  protected completeAuthorizationRequest(): Promise<AuthorizationRequestResponse | null> {
    return this.storageBackend.getItem(AUTHORIZATION_REQUEST_HANDLE_KEY).then((handle: string) => {
      if (handle) {
        // we have a pending request.
        // fetch authorization request, and check state
        return this.storageBackend
          .getItem(authorizationRequestKey(handle))
          .then((result: string) => JSON.parse(result))
          .then((json: AuthorizationRequestJson) => new AuthorizationRequest(json))
          .then((request: AuthorizationRequest) => this.processRedirect(request, handle));
      } else {
        return this.currentResponse;
      }
    });
  }
}
