import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Basepub } from "@orion2/models/basepub.models";
import { NavigationStart, Router, RouterEvent } from "@angular/router";
import { filter, take } from "rxjs";
import { ProductsService } from "@viewer/home/service/products.service";
import { PubDoc } from "@orion2/models/couch.models";

@Component({
  selector: "o-contract-modal",
  templateUrl: "./contract-modal.component.html",
  styleUrls: ["./contract-modal.component.scss"]
})
export class ContractModalComponent implements OnInit {
  public products: PubDoc[];
  public filterText: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public contract: Basepub.Contract,
    private dialog: MatDialogRef<ContractModalComponent>,
    private productsService: ProductsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getProducts();

    // We closes the modal on navigation (e.g when opening a pub or for replication)
    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationStart),
        take(1)
      )
      .subscribe(() => {
        this.dialog.close();
      });
  }

  private getProducts(): Promise<void> {
    return this.productsService
      .getOnlineProducts({ contractUid: this.contract.uidAhead })
      .then((pubs: PubDoc[]) => {
        this.products = pubs;
      });
  }
}
