import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Params, Router, RouterEvent } from "@angular/router";
import { ConfService, Store } from "@viewer/core";
import { Observable, filter, map, startWith } from "rxjs";
import { HomeRoute } from "@viewer/home/models";
import { DegradedStateService } from "libs/http/degraded-state/degraded-state.service";

interface TabData {
  id: "local" | "online" | "contracts";
  queryParams: Params;
  isVisible: boolean;
  disabledOnDegraded: boolean;
}

@Component({
  selector: "o-home-tabs",
  templateUrl: "./home-tabs.component.html",
  styleUrls: ["./home-tabs.component.scss"]
})
export class HomeTabsComponent implements OnInit, OnDestroy {
  public tabs: TabData[];
  public currentTab: Observable<string>;
  public hasBP2: boolean;

  constructor(
    private store: Store,
    private confService: ConfService,
    private router: Router,
    private degradedStateHandler: DegradedStateService
  ) {}

  public get isDegradedState(): Observable<boolean> {
    return this.degradedStateHandler.degradedState;
  }

  ngOnInit(): void {
    this.hasBP2 = this.confService.conf.hasBP2;
    this.tabs = this.getTabsData();
    this.currentTab = this.getCurrentTab();
  }

  ngOnDestroy(): void {
    this.store.homeFilterText = "";
  }

  public valueChange(value: string): void {
    // SPEC: Any string between two $ is a command. Ex: $dbstatus$
    if (/^\$.+\$$/.test(value)) {
      this.useCommand(value);
    } else {
      this.store.homeFilterText = value;
    }
  }

  private useCommand(input: string): void {
    const command = input.substring(1, input.length - 1);
    switch (command) {
      case "dbstatus":
        this.router.navigateByUrl(HomeRoute.DBSTATUS);
        break;
      default:
        break;
    }
  }

  private getTabsData(): TabData[] {
    return [
      { id: "local", queryParams: {}, isVisible: true, disabledOnDegraded: false },
      {
        id: "online",
        queryParams: this.hasBP2 ? { lastConsulted: true } : {},
        isVisible: true,
        disabledOnDegraded: false
      },
      {
        id: "contracts",
        queryParams: { lastConsulted: true },
        isVisible: this.hasBP2,
        disabledOnDegraded: true
      }
    ];
  }

  private getCurrentTab(): Observable<string> {
    return this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      startWith(this.router),
      map((event: RouterEvent) => event.url.match(/\/tabs\/([a-z]+)/)?.[1])
    );
  }
}
