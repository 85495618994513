import { SafeUrl, DomSanitizer } from "@angular/platform-browser";
import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "o-login-modal",
  templateUrl: "./login-modal.component.html",
  styleUrls: ["./login-modal.component.scss"]
})
export class LoginModalComponent implements OnInit, OnDestroy {
  public login_url: SafeUrl;

  private authRequestEndCallbackBind: () => {};

  constructor(
    private dialog: MatDialogRef<LoginModalComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) private data: { login_url: string }
  ) {}

  ngOnInit() {
    this.login_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.login_url);
    this.authRequestEndCallbackBind = this.authRequestEndCallback.bind(this);

    addEventListener("authRequestEnd", this.authRequestEndCallbackBind);
  }

  ngOnDestroy() {
    removeEventListener("authRequestEnd", this.authRequestEndCallbackBind);
  }

  public close(): void {
    this.dialog.close();
  }

  private authRequestEndCallback(): void {
    this.close();
  }
}
