import { Component } from "@angular/core";
import { HistoricDialogComponent } from "@viewer/toc-items/historic-module/historic-dialog/historic-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "o-historic",
  templateUrl: "./historic.component.html"
})
export class HistoricComponent {
  constructor(public matDialog: MatDialog) {}

  public showHistoric(): void {
    this.matDialog.open(HistoricDialogComponent, {
      panelClass: "historic-modal"
    });
  }
}
