import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { PubDoc } from "libs/models/couch.models";
import { Router } from "@angular/router";
import { Store } from "@viewer/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "@viewer-env/environment";
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
  ConfirmDialogResult
} from "libs/components/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { incrementalAdminVersion } from "@orion2/utils/constants.utils";
import { Basepub } from "@orion2/models/basepub.models";
import { Observable, catchError, filter, of, tap } from "rxjs";
import { DownloadProductService } from "libs/download/download-product.service";
import { DownloadInfo, DownloadState } from "libs/download/download.models";
import { PubDestroyModalComponent } from "@viewer/replication/pub-destroy-modal/pub-destroy-modal.component";
import { PubReplicateService } from "@viewer/replication/service/pub-replicate.service";
import { HomeRoute } from "@viewer/home/models";

@Component({
  selector: "o-card-pub-container",
  templateUrl: "./card-pub-container.component.html",
  styleUrls: ["./card-pub-container.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardPubContainerComponent {
  @Input() lastRev: PubDoc;
  @Input() oldRevs: PubDoc[];
  @Input() searchInPub: string;
  @Input() showOffline = false;
  @Input() isDownloading = false;
  public revisionStatus = Basepub.RevisionStatus;
  public displayOldRevs = false;
  public downloadState = DownloadState;
  public downloadInfo: Observable<DownloadInfo>;

  constructor(
    public store: Store,
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog,
    private downloadProductService: DownloadProductService,
    private pubReplicateService: PubReplicateService
  ) {}

  public canDownload(pub: PubDoc): boolean {
    return (
      !this.isDownloading &&
      pub.status !== "offline" &&
      pub.capabilities?.download !== false &&
      pub.isConsultable &&
      // SPEC: Deactivate download on browser in PROD
      !(environment.production && environment.platform === "browser")
    );
  }

  public canDownloadFiles(pub: PubDoc): boolean {
    return pub.filename && !this.isDownloading;
  }

  public canDelete(pub: PubDoc): boolean {
    return this.showOffline
      ? pub.status === "offline"
      : pub.status !== "offline" && !this.isDownloading && pub.hasStartedDownload;
  }

  public canPause(): boolean {
    return this.isDownloading;
  }

  /**
   * open modal if player3D capability is true
   * options are replicate : only 2D medias or all medias
   * then redirect to replicate route
   *
   * @param {PubDoc} pub
   */
  public replicate(pub: PubDoc): Promise<boolean> {
    if (
      pub.capabilities.player3D &&
      pub.versionImporter >= incrementalAdminVersion &&
      !pub.hasStartedDownload
    ) {
      const data: ConfirmDialogData = {
        message: [this.translate.instant("download.option.label")],
        confirmLabels: [this.translate.instant("download.option.all")],
        cancelLabels: [this.translate.instant("download.option.2d")]
      };

      this.dialog
        .open(ConfirmDialogComponent, { data })
        .afterClosed()
        .pipe(filter(Boolean))
        .subscribe((result: ConfirmDialogResult) => {
          pub.exclude3Dmedia = !result.confirm;
          return this.navigateToReplicate(pub);
        });
    } else {
      return this.navigateToReplicate(pub);
    }
  }

  public deletePub(pub: PubDoc, event: Event): void {
    this.stopPropagation(event);
    this.dialog.open(PubDestroyModalComponent, { data: { pub } });
  }

  public stopReplication(event: Event): Promise<void> {
    this.stopPropagation(event);
    return this.pubReplicateService.stopReplication();
  }

  public toggleOldRevs(event: Event): void {
    this.stopPropagation(event);
    this.displayOldRevs = !this.displayOldRevs;
  }

  public getDiskSize(pub: PubDoc): number {
    return this.store.getPubDiskSize(pub);
  }

  /**
   * Trigger the download of the archive associated to the given product.
   *
   * @param product The product to be downloaded.
   */
  public downloadFiles(product: PubDoc): void {
    this.isDownloading = true;
    this.downloadInfo = this.downloadProductService
      .downloadProduct(product.occurrenceCode, product.uidRev, product.filename)
      .pipe(
        catchError(() => of({ state: DownloadState.DONE, progress: 0 })),
        tap((data: DownloadInfo) => {
          this.isDownloading = data.state !== DownloadState.DONE;
        })
      );
  }

  private stopPropagation(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  private navigateToReplicate(pub: PubDoc): Promise<boolean> {
    return this.router.navigate([HomeRoute.REPLICATE, pub.packageId, pub.revision]);
  }
}
