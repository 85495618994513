import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DeactivateGuardEnum } from "@viewer/toc-items/note-module/note/deactivateGuardEnum";
@Component({
  selector: "o-confirm-dialog",
  templateUrl: "confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"]
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  // To access to Enum in html, we had create a getter
  get DeactivateGuardEnum() {
    return DeactivateGuardEnum;
  }

  close(choice: string) {
    this.dialogRef.close(choice);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
