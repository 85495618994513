import { Injectable, Injector } from "@angular/core";

import { reaction, runInAction } from "mobx";
import { Store } from "@viewer/core/state/store";
import { PouchService } from "@viewer/core/pouchdb";
import { TranslateService } from "@ngx-translate/core";
import { LOCATION_INITIALIZED, registerLocaleData } from "@angular/common";
import { DateAdapter } from "@angular/material/core";
import localeEn from "@angular/common/locales/en";
import localeDe from "@angular/common/locales/de";
import localeDeExtra from "@angular/common/locales/extra/de";
import localeFr from "@angular/common/locales/fr";
import localeFrExtra from "@angular/common/locales/extra/fr";
import localeEs from "@angular/common/locales/es";
import localeEsExtra from "@angular/common/locales/extra/es";
import { DocLanguage } from "@viewer/core/pouchdb/caller";

// SPEC: Used for date formatting
registerLocaleData(localeEn, "en");
registerLocaleData(localeDe, "de", localeDeExtra);
registerLocaleData(localeFr, "fr", localeFrExtra);
registerLocaleData(localeEs, "es", localeEsExtra);

/**
 * This method will ensure that the TranslateService will be ready before really start the application
 *
 * @param translate
 * @param injector
 */
export function translateInitializerFactory(translate: TranslateService, injector: Injector) {
  return () =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve());
      locationInitialized.then(() => {
        translate.addLangs(["en", "fr", "es", "de"]);
        translate.setDefaultLang("en");
        translate.use("en").subscribe({
          error: (err: Error) => {
            console.error("Problem with language initialization :", err);
          },
          complete: () => {
            resolve();
          }
        });
      });
    });
}

@Injectable({
  providedIn: "root"
})
export class LangService {
  lang: string;

  constructor(
    private store: Store,
    private pouchService: PouchService,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<Date>
  ) {
    reaction(
      () => ({
        lang: this.store.currentLanguage,
        pouchReady: this.store.pouchReady
      }),
      state => {
        if (state.lang && state.pouchReady) {
          this.updateLang(state.lang);
          return true;
        }
        return false;
      }
    );
  }

  /**
   * Change the language and save it to the local database if necessary
   *
   * @param lang
   * @memberof LangService
   */
  setLang(lang: string) {
    if (this.lang && lang !== this.lang) {
      this.lang = lang;
      this.pouchService.userCaller.updateLanguage(this.lang);
    }

    this.updateLang(lang);
    this.translate.use(lang);
    this.dateAdapter.setLocale(lang);
  }

  /**
   * Return the app language from local DB or from store
   *
   * @returns
   * @memberof LangService
   */
  getLang(): Promise<string> {
    if (!this.lang) {
      return this.pouchService.userCaller.getLanguage().then((docLang: DocLanguage) => {
        if (docLang) {
          this.lang = docLang.value;
        } else {
          // store.currentLanguage set to "en" in store service
          this.lang = this.store.currentLanguage;
        }
        return this.lang;
      });
    }
    return Promise.resolve(this.lang);
  }

  /**
   * Change store currentLanguage (used when reaction)
   *
   * @param lang
   * @memberof LangService
   */
  private updateLang(lang: string): void {
    runInAction(() => {
      // It will trigger the reaction function (cf: constructor)
      // Used to set to the good language on app init
      this.store.currentLanguage = lang;
    });
  }
}
