import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { Store, ViewerMessageService, PouchService, ConfService } from "@viewer/core";
import { PubReplicateService } from "@viewer/replication/service/pub-replicate.service";
import { runInAction } from "mobx";
import { TranslateService } from "@ngx-translate/core";
import { HomeResolver } from "@viewer/home/resolver/home.resolver";
import { PlayerService } from "@viewer/core/player/player.service";
import packageJSON from "@package-json";
import { isIos } from "@orion2/utils/functions.utils";
import { BasepubUserService } from "@viewer/core/basepub/basepub-user.service";
import { HttpMethod } from "libs/http/abstractHttp.service";
import { FullHttpService } from "libs/http/fullHttp.service";
import { PubDoc } from "@orion2/models/couch.models";
import { HomeRoute } from "@viewer/home/models";

@Injectable()
export class PubReplicateResolver implements Resolve<PubDoc> {
  constructor(
    public store: Store,
    private router: Router,
    private pouchService: PouchService,
    private homeResolver: HomeResolver,
    private replicateService: PubReplicateService,
    private messageService: ViewerMessageService,
    private translate: TranslateService,
    private playerService: PlayerService,
    private httpService: FullHttpService,
    private basepubUserService: BasepubUserService,
    private confService: ConfService
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Promise<PubDoc> {
    // To be sure that the identifiers are the real packageId and revision and no alias, we resolve them
    return this.pouchService.pubCaller
      .resolvePubId(route.paramMap.get("pubId"), route.paramMap.get("pubRev"))
      .then((doc: { aliasPackageId: string; packageId: string; revision: string }) => {
        if (!doc) {
          throw new Error("Doc not found");
        }
        return this.pouchService.pubCaller.getPub(`${doc.packageId}__${doc.revision}`);
      })
      .then((pub: PubDoc) =>
        this.init(route)
          .then(() => this.pouchService.switchPublication(pub))
          .then(() => {
            runInAction(() => {
              this.store.pubInfo = pub;
            });

            if (this.confService.conf.hasBP2 && this.store.isLoggedIn) {
              this.postTracking(pub.occurrenceCode);
            }

            if (window.hasOwnProperty("cordova")) {
              this.handleCordova(pub);
            } else {
              this.replicateService.startReplication();
            }
            return pub;
          })
      );
  }

  private init(route: ActivatedRouteSnapshot): Promise<void> {
    return this.store.pouchReady ? Promise.resolve() : this.homeResolver.resolve(route);
  }

  private downloadPlayer(version: string): Promise<Blob> {
    return this.httpService.sendRequest<Blob>(
      HttpMethod.GET,
      `db_secure/player_3d/player_lib/DS_${version}.zip`,
      { responseType: "blob" }
    );
  }

  private postTracking(occCode: string): void {
    this.basepubUserService.postTracking({
      objectId: occCode,
      objectType: "productRevision",
      date: new Date(),
      type: "download"
    });
  }

  private handleCordova(pub: PubDoc): void {
    window["cordova"].exec(
      res => {
        const freeSpace = parseFloat((res / Math.pow(10, isIos() ? 9 : 6)).toPrecision(3)) - 0.5;
        const pubSize = this.store.getPubDiskSize(pub) / Math.pow(10, 9); // Pub size in GB
        const totalDownload =
          this.replicateService.progressHandlers[this.store.pubInfo._id].state.totalDownloaded;

        if (freeSpace > pubSize - totalDownload) {
          // SPEC: Should only check if cordova, pub has 3D capability and user is logged in
          if (
            this.store.pubInfo?.capabilities?.player3D &&
            this.store.playerSettings?.["cordova"] &&
            this.store.isLoggedIn
          ) {
            // SPEC: No need for dialog since we already have the pub download bar
            this.playerService
              .checkPlayer(
                packageJSON.playerVersion,
                () => this.downloadPlayer(packageJSON.playerVersion),
                true
              )
              .catch(err => {
                // SPEC: We should not block the use of the pub if the player is not downloaded
                console.error(err);
                this.messageService.error(
                  this.translate.instant("download.player.error", {
                    version: packageJSON.playerVersion
                  })
                );
              });
          }
          this.replicateService.startReplication();
        } else {
          console.error(this.translate.instant("database.storage.warn"));
          this.messageService.error(this.translate.instant("database.storage.warn"));
          // Here we are in /home/replicate/:pubId/:pubrev
          // If we can't replicate due to space issues, we have to go back to /online
          this.router.navigate([HomeRoute.ONLINE], { queryParams: { lastConsulted: true } });
        }
      },
      err => {
        console.error("CORDOVA ERROR - FreeDiskSpace", err);
        console.error(this.translate.instant("database.storage.error"));
        this.messageService.error(this.translate.instant("database.storage.error"));
        // SPEC: Here, we let the user know that we couldn't retrieve disk space info
        // And ask him to retry
        this.router.navigate([HomeRoute.ONLINE], { queryParams: { lastConsulted: true } });
      },
      "File",
      "getFreeDiskSpace",
      []
    );
  }
}
