import xpath from "xpath";

export const ARRAY_SEPARATOR = "~~";
export const OBJECT_SEPARATOR = "@@";
export const STRING_SEPARATOR = "^^";

export class Util {
  public static cleanXml(xml: xpath.SelectedValue[]) {
    const str = xml.toString();
    return str.replace(/(\r\n|\n|\r)/gm, " ").trim();
  }

  /**
   * Return true if document is S1000D Norme
   *
   * @param xmlnodes
   */
  public static checkS1000D(xmlnodes): boolean {
    // we redefine the select function of xpath to access the namespace xsi
    const select = xpath.useNamespaces({
      xsi: "http://www.w3.org/2001/XMLSchema-instance"
    });
    const xsi = select("string(/dmodule/@xsi:noNamespaceSchemaLocation)", xmlnodes);
    // we check schedul.xsd for msm tasks because the field s1000d.org is not present
    // we should have http://www.s1000d.org/ on all S1000D xml document
    return !!(
      xsi.includes("schedul.xsd") ||
      xsi.includes("s1000d.org") ||
      xsi.includes("proced.xsd")
    );
  }

  /**
   * Return true if document is MSM / ALS S1000D Norme
   *
   * @param xmlnodes
   */
  public static checkTasksS1000D(xmlnodes: Node): boolean {
    // we redefine the select function of xpath to access the namespace xsi
    const select = xpath.useNamespaces({
      xsi: "http://www.w3.org/2001/XMLSchema-instance"
    });
    const xsi = select("string(/dmodule/@xsi:noNamespaceSchemaLocation)", xmlnodes);
    return !!xsi.includes("schedul.xsd");
  }

  public static checkVendors(xmlnodes: Document, isS1000D: boolean): boolean {
    const modelIdentCode = xmlnodes
      .querySelector("dmIdent > dmCode")
      ?.getAttribute("modelIdentCode");

    const systemDiffCode = xmlnodes
      .querySelector("dmIdent > dmCode")
      ?.getAttribute("systemDiffCode");

    const enterpriseCode = xmlnodes.querySelector("originator")?.getAttribute("enterpriseCode");

    return (
      (systemDiffCode === "B" && enterpriseCode !== "F0210") ||
      (modelIdentCode !== "H160" && isS1000D)
    );
  }

  // we check if wrngdata
  public static isWDMs1000d(xmlDoc: Document): boolean {
    if (!xmlDoc) {
      return false;
    }

    const dmodule = xmlDoc.querySelector("dmodule");

    return dmodule && !!dmodule.getAttribute("xsi:noNamespaceSchemaLocation").match(/wrngdata/g);
  }

  public static isFrontMatter(xmlDoc: Document): boolean {
    const dmodule = xmlDoc.querySelector("dmodule");
    return dmodule && dmodule.getAttribute("xsi:noNamespaceSchemaLocation") === "frontmatter.xsd";
  }

  // we check if we are on fullscreen view
  // we use it to know if we should open the note drawer
  public static isFullscreenView(url, view) {
    return (
      url.match(/fullscreen|stepbystep|\/ipc\/|\/pdf\//g) != null || view === "preprint_fullscreen"
    );
  }

  public static convertCSV(array: string[]): string {
    return array.join(";");
  }
}
