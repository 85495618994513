import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@viewer/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HistoricService } from "@viewer/core/toc-items/historic.service";
import { CmData } from "@viewer/shared-module/cm-preview/cm-preview.service";
import { HistoryElem } from "@orion2/models/tocitem.models";

export interface CmPreviewDataSource {
  metadata: CmData;
  dmc: string;
  anchor: string;
}

@Component({
  selector: "o-cm-preview",
  templateUrl: "./cm-preview.component.html",
  styleUrls: ["./cm-preview.component.scss"]
})
export class CmPreviewComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public datas: CmPreviewDataSource[],
    public dialog: MatDialogRef<CmPreviewDataSource>,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private historicService: HistoricService
  ) {}

  public openDu(data: CmPreviewDataSource): Promise<boolean> {
    const { dmc, anchor } = data;
    if (dmc === this.store.currentDMC) {
      return;
    }
    this.historicService.updateCurrentDMRoot({ dmc } as HistoryElem, this.store.currentDMC);
    return this.router.navigate(["du", dmc], {
      relativeTo: this.route.firstChild.firstChild,
      queryParams: { scroll: anchor }
    });
  }
}
