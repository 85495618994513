import { Injectable, Injector } from "@angular/core";
import { ItemBasket } from "@orion2/models/tocitem.models";

import { TocItemService } from "@viewer/core/toc-items/tocItem.service";
import { IpcService } from "@viewer/core/ipc/ipc.service";

import xpath from "xpath";
import { XmlDoc } from "@orion2/models/couch.models";

export interface DiscrepancyData {
  ipc: string;
  rev: string;
  model: string;
  chap: string;
  ata_desc: string;
  pos: string;
  desc: string;
  pnr: string;
  mfc: string;
  qty: number;
  shortDmc: string;
}

@Injectable()
export class ShoppingService extends TocItemService {
  protected tiType = "shopping";
  protected tiScope = "private";

  private ipcService: IpcService;

  constructor(injector: Injector) {
    super(injector);

    this.ipcService = injector.get(IpcService);
  }

  /**
   * Initialise an Item Basket from an IpcItem
   *
   * @param ipcItem
   */
  initItemBasket(ipcItem: ItemBasket) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const qty = isNaN(parseInt(ipcItem.qty as any, 10)) ? 1 : parseInt(ipcItem.qty as any, 10);
    if (ipcItem._id !== undefined) {
      return { ...ipcItem };
    } else {
      return {
        ...ipcItem,
        // As an id we set the type and dmc in first to be able easily to acces all the items of one given dmc directly using
        //  couchdb keys in the future. We also add the ipcItem.id (CSN) to add unicity between keys.
        _id:
          this.tiType +
          "__" +
          (ipcItem.dmc ?? this.store.currentDMC) +
          "__" +
          this.store.publicationRevision +
          "__" +
          ipcItem.mpn +
          "__" +
          ipcItem.mfc,
        title: ipcItem.title ?? this.store.currentTocNode.shortTitle,
        versions: ipcItem.versions?.join() ?? this.store.currentTocNode.versions.join(),
        dmc: ipcItem.dmc ?? this.store.currentDMC,
        mediaId: this.store.currentMediaId,
        reference: ipcItem.reference ?? this.titleService.headerSubtitle,
        qty,
        qtyInBasket: qty,
        // We store some info to be able later to reconstruct direct links to the target part
        packageId: this.store.pubInfo.packageId,
        revision: this.store.pubInfo.revision,
        minRevision: this.store.publicationRevision,
        type: this.tiType,
        parents: [this.tiType],
        allIsn: JSON.stringify(ipcItem.allIsn),
        addingDate: Date.now(), // Used to sort basket by date
        label: ipcItem.label?.trim() // SPEC: We need to trim because for some reasons there is a \n in the label data
      };
    }
  }

  /**
   * Add item in shopping basket
   *
   * @param item
   * @param message
   * @param onUndo - Callback function to call when undo the deletion
   * @memberof ShoppingService
   */
  addItemToBasket(item: ItemBasket, message = true, onUndo?: () => void): Promise<boolean> {
    // We ensure here that the item can be added to the shopping basket
    if (item.mpn === undefined || item.mpn === "" || this.ipcService.isNoNumber(item.mpn)) {
      const alrMsg = this.translate.instant("shopping.notAddable");
      this.messageService.warning(item.label + alrMsg);
      return Promise.resolve(false);
    }

    // To ensure that the item isn't already in the shopping basket we try to find it in it using item.id which
    //   is unique as it corresponds to the CSN of the item.
    return this.getItemsOfType().then((inBasket: ItemBasket[]) => {
      if (!inBasket || this.isInBasket(inBasket, item.csn, item.applicableVersion)) {
        const alrMsg = this.translate.instant("shopping.alreadyMsg");
        this.messageService.warning(item.label + alrMsg);
        return Promise.resolve(false);
      } else {
        // Forcing the push and updateItemOfType since ipc-card needs the info and is using tocItemOfType because
        // _id does not begin with dmc
        return this.save(item, this.tiScope, true, true).then((res: boolean) => {
          if (res && message) {
            const addedMsg = this.translate.instant("shopping.addedMsg");
            const undoMsg = onUndo ? this.translate.instant("undo") : undefined;
            this.messageService.success(item.label + addedMsg, undoMsg);
            if (onUndo) {
              this.messageService.onAction.subscribe(onUndo);
            }
          }
          return res;
        });
      }
    });
  }

  /**
   * Check whether an item is already in the basket
   *
   * @param inBasket
   * @param csn - the csn to search
   * @param applicableVersion - the applicableVersion to search
   */
  public isInBasket(inBasket: ItemBasket[], csn: string, applicableVersion: string): boolean {
    return inBasket?.some(
      (item: ItemBasket) => item.csn === csn && item.applicableVersion === applicableVersion
    );
  }

  /**
   * Create an standard undo function to pass to the addItemToBasket 'onUndo' function using the given particular set of items
   *
   * @param itemsToRemove - The set of added items to remove
   */
  undoAddFactory(itemsToRemove: ItemBasket[]): () => void {
    return () =>
      Promise.all(itemsToRemove.map(item => this.deleteWithUndo(item)))
        .then(() => {
          const undoMessage = this.translate.instant("undo.ok");
          this.messageService.success(undoMessage);
          return Promise.resolve(true);
        })
        .catch(() => {
          const errMessage = this.translate.instant("undo.error");
          this.messageService.error(errMessage);
          return Promise.resolve(false);
        });
  }

  /**
   * Get all the shopping basket items
   */
  public async getShoppingItems(): Promise<ItemBasket[]> {
    return this.getItemsOfType(this.tiType, this.tiScope) as unknown as ItemBasket[];
  }

  /**
   * Remove all items for the current item basket
   *
   * @param savedList - An array of deleted items to pass to undoDeleteFactory function
   */
  async deleteAll(savedList: ItemBasket[]): Promise<boolean> {
    const listCopy: ItemBasket[] = savedList.map(item => ({ ...item }));
    return this.getItemsOfType()
      .then((items: ItemBasket[]) =>
        Promise.all(items.map(item => this.delete(item))).then(deletions => {
          // If some deletion(s) went wrong (ex: [true, false, true, ...], we log an error to tell user that the deletion is partial
          if (!deletions.reduce((prev, next) => prev && next, true)) {
            const errMessage = this.translate.instant("shopping.errorPartial");
            this.messageService.error(errMessage);
            return false;
          }

          const deleteMsg = this.translate.instant("shopping.deleteAll");
          const undoMsg = this.translate.instant("undo");
          this.messageService.success(deleteMsg, undoMsg);

          // Leave the possibility to undo the deletion via a button in the snackbar
          this.messageService.onAction.subscribe(this.undoDeleteFactory(listCopy));
          return true;
        })
      )
      .catch(() => {
        const errMessage = this.translate.instant("shopping.errorDeleteAll");
        this.messageService.error(errMessage);
        return Promise.resolve(false);
      });
  }

  /**
   * Get all discrepancy portal objects from a list of ItemBasket
   *
   * @param shoppings
   * @returns
   * @memberof ShoppingService
   */
  public getDiscrepancyPortalData(shoppings: ItemBasket[]): Promise<DiscrepancyData[]> {
    const xml_ids = shoppings.map(s => s.dmc);
    return this.pouchService.xmlCaller.get(xml_ids).then((docs: XmlDoc[]) =>
      shoppings.map(shopping => {
        const xmlDoc = docs.find(doc => doc._id === shopping.dmc);
        const xml = new DOMParser().parseFromString(xmlDoc.data, "text/xml");
        return this.createDiscrepancyPortalObj(xml, shopping);
      })
    );
  }

  /**
   * Create a standard undo function to pass to the deleteWithUndo function
   *
   * @param itemsToRestore - The set of deleted items to restore
   */
  protected undoDeleteFactory(
    itemsToRestore: ItemBasket | ItemBasket[]
  ): () => Promise<ItemBasket[]> {
    return () => {
      const docToSave = Array.isArray(itemsToRestore) ? itemsToRestore : [itemsToRestore];

      const saveProm = docToSave.map((doc: ItemBasket) => {
        delete doc._rev;
        return this.addItemToBasket(doc, false);
      });

      return Promise.all(saveProm)
        .then(() => {
          const undoMessage = this.translate.instant("undo.ok");
          this.messageService.success(undoMessage);
          // We need to refresh the ItemOftypes as save() function doesn't do it by default.
          // To update the datasource in shopping list
          return this.getItemsOfType() as Promise<ItemBasket[]>;
        })
        .catch(() => {
          const errMessage = this.translate.instant("undo.error");
          this.messageService.error(errMessage);
          return [] as ItemBasket[];
        });
    };
  }

  /**
   * Init and create a discrepancy portal object
   * from a xml and a ItemBasket
   *
   * @private
   * @param xml
   * @param shopping
   * @returns
   * @memberof ShoppingService
   */
  // SPEC: Missing xpath for S1000D aircraft
  private createDiscrepancyPortalObj(xml: Document, shopping: ItemBasket): DiscrepancyData {
    const isn = shopping.isn.find(isnObj => isnObj.pnr === shopping.mpn);

    const isnXml = xpath.select1(
      `dmodule/content/IPC/CSN[@CSN="${shopping.id}"]/ISN[@ID="${isn.id}"]`,
      xml
    ) as Node;
    const dmAdress = xpath.select1("dmodule/identAndStatusSection/dmAddress", xml) as Node;
    const wp6Status = xpath.select1("dmodule/WP6Status", xml) as Node;

    const desc = xpath
      .select("(PAS/DFP | CBS/DFL)/text()", isnXml)
      .map((value: Node) => value.nodeValue);

    return {
      ipc: xpath.select1(
        "concat(dmIdent/dmCode/@modelIdentCode, dmIdent/identExtension/@extensionCode)",
        dmAdress
      ) as string,
      rev: xpath.select1(
        "concat(dmAddressItems/issueDate/@year,'.', dmAddressItems/issueDate/@month,'.', dmAddressItems/issueDate/@day)",
        dmAdress
      ) as string,
      model: this.store.pubInfo.verbatimText,
      chap: xpath.select1("string(simpleShortDmc)", wp6Status) as string,
      ata_desc: shopping.title,
      pos: shopping.hotspotId,
      desc: `"${desc.join(" ")}"`,
      pnr: shopping.mpn,
      mfc: shopping.mfc,
      qty: +shopping.qty,
      shortDmc: shopping.reference
    };
  }
}
