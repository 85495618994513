import { AppAuthError } from "@openid/appauth/built/errors";
import { AuthFlowConfiguration } from "@orion2/auth/auth.flow.conf";
import { OakRequest } from "@orion2/auth/oak.request";
import { OakError, OakErrorJson, OakResponse, OakResponseJson } from "@orion2/auth/oak.response";
import { MimeType } from "@orion2/models/enums";
import { CustomRequestor } from "./auth.flow";

/**
 * The default token request handler.
 */
export class OakRequestHandler {
  private _promise: Promise<OakResponse>;
  private _resolve: (value?: OakResponse | PromiseLike<OakResponse>) => void;
  private _reject: (reason?: AppAuthError | { code: number; message: Error }) => void;
  private requestor: CustomRequestor;

  constructor() {
    this._promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  get promise(): Promise<OakResponse> {
    return this._promise;
  }

  resetPromise() {
    this._promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  public setRequestor(requestor: CustomRequestor): void {
    this.requestor = requestor;
  }

  public performOakRequest(
    configuration: AuthFlowConfiguration,
    request: OakRequest
  ): Promise<OakResponse> {
    let url = `${configuration.baseUrl}/oak`;
    if (request.occCode) {
      url += `?occCode=${request.occCode}`;
    }

    const settings = {
      url,
      method: "GET",
      dataType: "json",
      headers: {
        Authorization: `Bearer ${request.bearer.access_token}`,
        "Content-Type": MimeType.JSON,
        Origin: configuration.baseUrl,
        Referer: configuration.baseUrl
      }
    };
    if (request.oak) {
      settings.headers["oak"] = request.oak;
    }

    this.requestor
      .xhrWithErrorBody<OakResponseJson | OakErrorJson>(settings)
      .then((response: OakResponseJson | OakErrorJson) => {
        if (this.isOakResponse(response)) {
          this._resolve(new OakResponse(response, request.bearer.access_token));
        } else {
          this._reject(new AppAuthError(response.error, new OakError(response)));
        }
      })
      // TODO DG rework the error message
      .catch((err: Error) => this._reject({ code: 400, message: err }));
    return this._promise;
  }

  private isOakResponse(response: OakResponseJson | OakErrorJson): response is OakResponseJson {
    return (response as OakErrorJson).error === undefined;
  }
}
