import {
  DBAppSchemaEnum,
  DBPubSchemaEnum,
  PubSchema,
  Capabilities
} from "libs/transfert/model/pubSchema";
import { Basepub } from "@orion2/models/basepub.models";
import { TocItem } from "@orion2/models/tocitem.models";
import type PouchDB from "pouchdb";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface PouchAllDocsResult<T = any> {
  total_rows: number;
  offset: number;
  rows: T[];
}

export type PouchDBAllDocsWithError<T> = PouchDB.Core.AllDocsResponse<T> | PouchDB.Core.Error;
export type PouchDBAllDocsWithKeysRows<T> = PouchDB.Core.AllDocsWithKeysResponse<T>["rows"][0];
export type PouchDBAllDocsRowError<T> = Extract<PouchDBAllDocsWithKeysRows<T>, { error: string }>;

export interface PouchDBSessionResponse {
  ok: boolean;
  userCtx: {
    name: string;
    roles: string[];
  };
  info: {
    authentication_handlers: string[];
    authenticated: string;
  };
}

export interface PouchDBReplicationParam extends PouchDB.Replication.ReplicateOptions {
  return_docs: boolean;
}

export interface PouchDBFindOption<T> extends PouchDB.Find.FindRequest<T> {
  bookmark: string;
}

export interface PouchRows<T> {
  doc?: PouchDB.Core.ExistingDocument<T & PouchDB.Core.AllDocsMeta>;
  id: PouchDB.Core.DocumentId;
  key: PouchDB.Core.DocumentKey;
  value: {
    rev: PouchDB.Core.RevisionId;
    deleted?: boolean;
  };
}

export interface PouchChangesSequence {
  seq: string;
  id: string;
  changes: [
    {
      rev: string;
    }
  ];
}

export interface PouchRevDiffResponse {
  [_id: string]: {
    missing: string[];
    possible_ancestors: string[];
  };
}

export interface PouchSaveResponse {
  ok: boolean;
  id: string;
  rev: string;
}

export interface DbStats {
  dbName: string;
  dbInstance: DBAppSchemaEnum | DBPubSchemaEnum;
  remoteCount: number;
  localCount: number;
  percent: number;
}

export interface DbItemInterface {
  _id: string;
  _rev?: string;
  _deleted?: boolean;
  _conflicts?: string[];
  _attachments?: PouchDB.Core.Attachments;
  date?: Date;
  lastUpdate?: Date;
}

export interface TocInfo extends DbItemInterface {
  title: string;
  minRevision: string;
  maxRevision?: string;
  applicabilityMD5: string;
  parent: string;
  versions?: string[];
  code?: string;
  pm_number?: string;
  level?: string;
  dmcFull?: string;
  revision?: string;
  reference?: string;
  dmc?: string;
  xslt?: string;
  shortTitle?: string;
  isPreprint?: boolean;
  order_manual?: string;
  editedTitle?: string;
  referenceFolder?: string;
}

export interface ExternalDoc {
  baseUrl: string;
  variant: string;
  refManual: string;
  lang: string;
  dmMatrice: string;
}

export interface PubDoc extends DbItemInterface {
  packageId: string;
  subtitle: string;
  jacket?: string;
  verbatimText: string;
  priority: number;
  manualCode: string;
  versionImporter: string;
  import_date: string | Date;
  uidRev: string;
  revision: string;
  revisionDate: string | Date;
  isS1000D: boolean;
  isPackagePDF: boolean;
  product?: string;
  isPublished: boolean;
  lang: string;
  dbs: { [key: string]: number };
  sizes: { [key: string]: { disk: number; data: number } };
  occurrenceCode: string;
  pubSchema: PubSchema;
  capabilities: Capabilities;
  minRelease: string;
  reasonForUpdate: string[];
  model?: string;
  versions?: string[];
  externalDoc?: {
    [entrepriseCode: string]: ExternalDoc;
  };
  specificParams?: {
    [key: string]: unknown;
  };
  shouldUpdate?: boolean;
  isArchived?: boolean;
  // SPEC: Build is only for normal package
  buildInfo?: string;
  // SPEC: Only setted for pdf package
  imgName?: string;
  revNumber?: string;
  indiceVersion?: string;
  // SPEC: Only set to true by default
  displayRevision?: boolean;
  // SPEC: Setted only inside in admin-server for ready for import package
  path?: string;
  // SPEC: Theses attributes are only used inside the admin
  basePackageId?: string;
  // SPEC: Theses attributes are setted inside the viewer only
  exclude3Dmedia?: boolean;
  hasStartedDownload?: boolean;
  status?: Basepub.RevisionStatus | "offline";
  isLast?: boolean;
  infos?: string;
  filename?: string;
  fileSize?: number;
  consultUrl?: string;
  manualCategory?: string;
  isConsultable?: boolean;
  productType?: ProductType;
  classifications?: string[];
}

export enum ProductType {
  ONLINE = "Online",
  OFFLINE = "Offline"
}

export interface XmlDoc extends DbItemInterface {
  minRevision: string;
  data: string;
}

export interface ApplicDoc extends DbItemInterface {
  data: string[];
}

export interface DesignDoc extends DbItemInterface {
  validate_doc_update?: string;
  views?: {
    [viewName: string]: {
      map: string;
      reduce?: string;
      xml?: string;
    };
  };
  lists?: {
    [key: string]: string;
  };
  updates?: {
    updatefun: string;
  };
}

export interface UserDoc extends DbItemInterface {
  login: string;
  email: string;
  firstname: string;
  lastname: string;
  pwd: string;
  role: string;
  creationDate: Date;
  lastupdate: Date;
}

export type SearchDoc = DbItemInterface;

export interface PdfDoc extends DbItemInterface {
  mediaId: string;
  minRevision: string;
  _attachments: PouchDB.Core.Attachments;
}

export interface TaskDoc extends DbItemInterface {
  _id: string;
  minRevision: string;
  applicabilityMD5: string;
  data: string[];
}

export interface PartDoc extends DbItemInterface {
  _id: string;
  minRevision: string;
  applicabilityMD5: string;
  data: string[];
}

export interface PubDocWithoutJacket {
  packageId: string;
  pubSchema: PubSchema;
  revision: string;
  isPackagePDF: boolean;
}

export type PubMandatoryMeta = PubDocWithoutJacket | PubDoc;

export interface MediaObject extends DbItemInterface {
  data: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  FORMAT: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NOM_LOGIQUE: string;
}

export type MediaObjects = MediaObject[];

export type IncrementalDoc = PdfDoc | MediaObject;

export type DbItem =
  | XmlDoc
  | ApplicDoc
  | TocItem
  | TocInfo
  | MediaObject
  | SearchDoc
  | PdfDoc
  | DesignDoc
  | TaskDoc
  | UserDoc;
