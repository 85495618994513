import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { addElementOnClick } from "@orion2/utils/functions.utils";
import { BasicViewService } from "@viewer/content-provider/basic-view.service";
import { Store } from "@viewer/core";
import { SearchResult } from "@viewer/core/search/searchModel";
import { IReactionDisposer, reaction, runInAction } from "mobx";
import xpath from "xpath";

export interface FrontMatterRowObject {
  docUnit: string;
  title: string;
  status: string;
  publicationDate: string;
  reasonForUpdate: string;
  href: string;
}

@Component({
  selector: "o-front-matter",
  templateUrl: "./front-matter.component.html",
  styleUrls: ["./front-matter.component.scss"]
})
export class FrontMatterComponent implements OnInit, OnDestroy {
  duObjectReaction: IReactionDisposer;
  frontMatterTable: FrontMatterRowObject[] = [];
  pubRev: string;
  pubDate: string;
  constructor(
    public store: Store,
    public basicViewService: BasicViewService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.duObjectReaction = reaction(
      () => this.store.duObject,
      () => {
        this.getFrontMatterInfos();
        this.setDocUnitLinks();
      },
      { fireImmediately: true }
    );
  }

  getFrontMatterInfos() {
    const xmlnodes = this.store.duObject.xml;
    if (!xmlnodes) {
      return;
    }
    this.pubRev = xpath
      .select(
        // eslint-disable-next-line max-len
        "concat(/dmodule/content/frontMatter/frontMatterList/issueInfo/@issueNumber, '.', /dmodule/content/frontMatter/frontMatterList/issueInfo/@inWork)",
        xmlnodes
      )
      .toString();
    this.pubDate = xpath
      .select(
        // eslint-disable-next-line max-len
        "concat(/dmodule/content/frontMatter/frontMatterList/issueDate/@year,'.', /dmodule/content/frontMatter/frontMatterList/issueDate/@month,'.',/dmodule/content/frontMatter/frontMatterList/issueDate/@day)",
        xmlnodes
      )
      .toString();
    const fromtMatterRows = xpath.select(
      "/dmodule/content/frontMatter/frontMatterList/frontMatterSubList/frontMatterDmEntry",
      xmlnodes
    );
    fromtMatterRows.forEach((row: Node) => {
      const frontMatterRowObject = {} as FrontMatterRowObject;

      const dmCode = xpath.select("dmRef/dmRefIdent/dmCode", row)[0];

      frontMatterRowObject.docUnit = xpath
        .select(
          // eslint-disable-next-line max-len
          "concat(@modelIdentCode,'-',@systemDiffCode,'-',@systemCode,'-',@subSystemCode,@subSubSystemCode,'-',@assyCode,'-',@disassyCode,@disassyCodeVariant,'-',@infoCode,@infoCodeVariant,'-',@itemLocationCode)",
          dmCode as Node
        )
        .toString();

      frontMatterRowObject.title = xpath
        .select(
          "concat(dmRef/dmRefAddressItems/dmTitle/techName,' - ',dmRef/dmRefAddressItems/dmTitle/infoName)",
          row
        )
        .toString();
      frontMatterRowObject.status = xpath.select("string(@issueType)", row).toString();
      frontMatterRowObject.publicationDate = xpath
        .select(
          // eslint-disable-next-line max-len
          "concat(dmRef/dmRefAddressItems/issueDate/@year, '.', dmRef/dmRefAddressItems/issueDate/@month, '.', dmRef/dmRefAddressItems/issueDate/@day)",
          row
        )
        .toString();
      frontMatterRowObject.reasonForUpdate = this.getReasonForUpdate(
        xpath.select("string(reasonForUpdate/@updateReasonType)", row).toString()
      );

      const mapResult = this.store.referenceToResultMap.find((el: SearchResult) =>
        el.dmc.includes(frontMatterRowObject.docUnit.split("H160-")[1])
      );

      // if we found docUnit in the referenceToResultMap, we want to do a external link
      // and change the docUnit by the shortDMC corresponding
      frontMatterRowObject.href = mapResult ? mapResult.dmc : undefined;

      frontMatterRowObject.docUnit = mapResult ? mapResult.reference : frontMatterRowObject.docUnit;

      this.frontMatterTable.push(frontMatterRowObject);
    });
    this.cdr.detectChanges();
    runInAction(() => {
      this.store.displayDone = true;
    });
  }

  setDocUnitLinks() {
    const links = document.querySelectorAll(".span_links");
    links.forEach(link => {
      addElementOnClick(link, link.getAttribute("id"), link.innerHTML);
      this.basicViewService.addDuLinks(document);
    });
  }

  getReasonForUpdate(codeURT: string): string {
    switch (codeURT) {
      case "urt01":
        return "reason.update.01";
      case "urt02":
        return "reason.update.02";
      case "urt03":
        return "reason.update.03";
      case "urt04":
        return "reason.update.04";
      case "urt05":
        return "reason.update.05";
      default:
        return "";
    }
  }

  ngOnDestroy() {
    this.duObjectReaction();
  }
}
