import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import { Injectable, Injector } from "@angular/core";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { Bookmark, TocItemTree } from "@orion2/models/tocitem.models";
import { BookmarkService, TocItemService } from "@viewer/core";
import {
  FlatTreeNode,
  TreeExport,
  TreeNode
} from "@viewer/toc-items/bookmark-module/bookmark-list/models";
import { DownloadFileService } from "libs/download/download-file.service";
import { cloneDeep } from "lodash";
import { reaction } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { BookmarkImportResultDialogComponent } from "@viewer/toc-items/bookmark-module/bookmark-import-result-dialog/bookmark-import-result-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
  providedIn: "root"
})
export class BookmarkTreeService extends TocItemService {
  public treeControl: FlatTreeControl<FlatTreeNode>;
  public dataSource: MatTreeFlatDataSource<TreeNode, FlatTreeNode>;
  public expansionModel = new SelectionModel<string>(true);
  public tree: TocItemTree;

  protected tiType = "bookmarks_tree";
  protected tiScope = "private";

  private treeFlattener: MatTreeFlattener<TreeNode, FlatTreeNode>;

  constructor(
    injector: Injector,
    private bookmarkService: BookmarkService,
    private downloadService: DownloadFileService,
    private dialog: MatDialog
  ) {
    super(injector);
    reaction(
      () => ({
        pubId: this.store.publicationID,
        pubRev: this.store.publicationRevision
      }),
      () => {
        this.treeFlattener = this.buildTreeFlattener();
        this.treeControl = this.buildTreeControl();
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        this.getTree();
      },
      { fireImmediately: true }
    );
  }

  public getTree(): Promise<TreeNode[]> {
    return this.getItemsOfType(this.tiType).then((trees: TocItemTree[]) => {
      if (trees.length) {
        // we take the tree with the greater minRevison
        this.tree = trees.reduce((prev: TocItemTree, current: TocItemTree) =>
          prev.minRevision > current.minRevision ? prev : current
        );
      } else {
        // we create the tree
        this.tree = {
          _id: `${this.tiType}__${this.store.publicationRevision}`,
          data: this.createTree(),
          type: this.tiType,
          minRevision: this.store.publicationRevision
        };
      }
      return this.addOrphans(this.tree.data).then((tree: TreeNode[]) => {
        this.refreshData(tree);
        return tree;
      });
    });
  }

  /**
   * add bookmarks in folders
   */
  public addBookmarks(bookmarks: Bookmark[], destinations: string[]): Promise<boolean> {
    const oldTree = cloneDeep(this.dataSource.data);
    if (bookmarks.length !== destinations.length) {
      return Promise.resolve(false);
    }
    bookmarks.forEach((bookmark, index) => {
      this.getNodeById(destinations[index]).children.unshift({ id: bookmark._id });
    });
    return this.updateTree(oldTree, this.dataSource.data);
  }

  /**
   * add a folder in parentId
   */
  public addFolder(parentId: string, title: string): Promise<boolean> {
    const oldTree = cloneDeep(this.dataSource.data);
    const folder = {
      id: `folder_${uuidv4()}`,
      name: title,
      children: []
    };
    this.getNodeById(parentId).children.push(folder);
    this.expansionModel.select(parentId);
    return this.updateTree(oldTree, this.dataSource.data);
  }

  /**
   * change a folder name
   */
  public editFolder(folderId: string, title: string): Promise<boolean> {
    const oldTree = cloneDeep(this.dataSource.data);
    this.getNodeById(folderId).name = title;
    return this.updateTree(oldTree, this.dataSource.data);
  }

  /**
   * export a json file containing the metadata of a folder
   */
  public exportFolder(folderId: string, bookmarks: Bookmark[]): Promise<void> {
    const treeNode = this.getNodeById(folderId);
    const bookmarkInside = this.getBookmarkInside(folderId);
    const docs = bookmarks.filter((bookmark: Bookmark) => bookmarkInside.includes(bookmark._id));
    const exportJSON = {
      occurrenceCode: this.store.pubInfo.occurrenceCode,
      revision: this.store.pubInfo.revision,
      type: "bookmarks",
      tree: treeNode,
      bookmarks: docs
    } as TreeExport;

    const filename = `BOOKMARKS_${this.store.pubInfo.packageId}_${this.store.pubInfo.revision}_${treeNode.name}`;
    const file = new File(
      [new Blob([JSON.stringify(exportJSON)], { type: "application/json" })],
      filename + ".json"
    );

    return this.downloadService.downloadAsZip(file, filename);
  }

  /**
   * import folder metadatas from an export
   */
  public importFolder(treeExport: TreeExport): Promise<void> {
    const errors: string[] = [];
    const success: string[] = [];
    const importPromises = [];
    // SPEC: we always import the folders because we accept duplicate folder name
    this.getExportFolders([treeExport.tree])
      .map((folder: TreeNode) => folder.name)
      .forEach((title: string) => {
        success.push(`${this.translate.instant("tocItem.folder")} ${title}`);
      });

    treeExport.bookmarks.forEach((exportedBookmark: Bookmark) => {
      let errorMessage;
      // we do not accept bookmarks if DMC is not found in the publication
      if (!this.store.getDUMeta(exportedBookmark.dmc)) {
        errorMessage = "bookmarks.error.found";
      }
      if (errorMessage) {
        errors.push(
          this.translate.instant(errorMessage, {
            title: exportedBookmark.title,
            dmc: exportedBookmark.dmc
          })
        );
        this.removeFromTree(exportedBookmark._id, treeExport.tree);
      } else {
        const importBookmark = this.createImportBookmark(treeExport.tree, exportedBookmark);
        const prom = this.bookmarkService.save(importBookmark).then((isSaved: boolean) => {
          if (isSaved) {
            success.push(exportedBookmark.title);
          } else {
            errors.push(
              this.translate.instant("bookmarks.error.save", {
                title: exportedBookmark.title,
                dmc: exportedBookmark.dmc
              })
            );
          }
          return isSaved;
        });
        importPromises.push(prom);
      }
    });
    return Promise.all(importPromises).then(() =>
      this.addTree(treeExport.tree).then(() => {
        this.dialog.open(BookmarkImportResultDialogComponent, {
          panelClass: "bookmark-import-result-dialog",
          data: {
            success,
            errors
          }
        });
      })
    );
  }

  /**
   * move item (folder or bookmark) to the destination
   */
  public moveItem(itemToMove: string, newId: string, destination: string): Promise<boolean> {
    const oldTree = cloneDeep(this.dataSource.data);
    // remove from old folder
    const treeNodes = this.flattenTree(this.dataSource.data);
    const item = treeNodes.find((node: TreeNode) => node.id === itemToMove);
    const oldParent = treeNodes.find((node: TreeNode) =>
      node.children?.map((child: TreeNode) => child.id).includes(itemToMove)
    );
    const index = oldParent.children.map((child: TreeNode) => child.id).indexOf(itemToMove);
    oldParent.children.splice(index, 1);
    // add to new folder
    const newFolder = treeNodes.find((node: TreeNode) => node.id === destination);
    item.id = newId;
    if (destination === oldParent.id) {
      newFolder.children.splice(index, 0, item);
    } else {
      newFolder.children.unshift(item);
    }
    this.expansionModel.select(destination);
    return this.updateTree(oldTree, this.dataSource.data);
  }

  /**
   * remove item (bookmark or folder) from the tree
   */
  public deleteItem(itemId: string): Promise<string[]> {
    const oldTree = cloneDeep(this.dataSource.data);
    const bookmarkList = this.getBookmarkInside(itemId);
    const parent = this.getParent(itemId, this.dataSource.data);
    const index = parent.children.map((child: TreeNode) => child.id).indexOf(itemId);
    parent.children.splice(index, 1);
    return this.updateTree(oldTree, this.dataSource.data).then(() => bookmarkList);
  }

  public updateTree(oldTree: TreeNode[], newTree: TreeNode[]): Promise<boolean> {
    const oldTreeDoc = {
      ...this.tree,
      data: oldTree
    };

    const newTreeDoc = {
      ...this.tree,
      data: newTree
    };

    return this.update(oldTreeDoc, newTreeDoc, this.tiScope, this.tiScope, true).then(
      (resp: boolean) => {
        this.tree = newTreeDoc;
        this.refreshData(this.tree.data);
        return resp;
      }
    );
  }

  public hasChild(_: number, nodeData: FlatTreeNode): boolean {
    return nodeData.expandable;
  }

  public isRoot(_: number, nodeData: FlatTreeNode): boolean {
    return nodeData.id === "root";
  }

  /**
   * get the folder parent node of given bookmark id
   */
  public getParent(bookmarkId: string, nodes: TreeNode[]): TreeNode {
    return this.flattenTree(nodes).find((node: TreeNode) =>
      node.children?.map((child: TreeNode) => child.id).includes(bookmarkId)
    );
  }

  /**
   * Does the folder have the given bookmark id
   */
  public folderHasBookmarkId(node: TreeNode, bookmarkId: string): boolean {
    return node.children?.map((child: TreeNode) => child.id).includes(bookmarkId);
  }

  /**
   * get all folders in tree
   */
  public getFolders(): TreeNode[] {
    return this.flattenTree(this.dataSource.data).filter((node: TreeNode) => node.children);
  }

  /**
   * return tree if the object is a TreeExport
   */
  public isTreeExport(object: Object): object is TreeExport {
    return (
      "occurrenceCode" in object &&
      "revision" in object &&
      "type" in object &&
      object["type"] === "bookmarks" &&
      "tree" in object &&
      "bookmarks" in object
    );
  }

  /**
   * get the maximum depth of a treeNode
   */
  public getMaxDepth(node: TreeNode): number {
    if (!node.children) {
      return 0;
    }
    if (!node.children.length) {
      return 1;
    }
    const childDepths = node.children.map((child: TreeNode) => this.getMaxDepth(child));
    return Math.max(...childDepths) + 1;
  }

  public moveNode(previousIndex: number, targetIndex: number): Promise<boolean> {
    const oldTree = cloneDeep(this.dataSource.data);

    // Delete node from its old location
    const node = this.getNodeAtIndex(this.dataSource.data, previousIndex);
    const oldSiblings = this.getParent(node.id, this.dataSource.data).children;
    const oldIndex = oldSiblings.findIndex((sibling: TreeNode) => sibling.id === node.id);
    oldSiblings.splice(oldIndex, 1);

    // Insert node to its new location
    const targetNode = this.getNodeAtIndex(this.dataSource.data, targetIndex);

    // SPEC: If the node above the destination is a folder and is currently expanded,
    // we set the moved node as a child of this folder
    if (targetNode.children && this.expansionModel.isSelected(targetNode.id)) {
      if (this.canMove(node, targetNode)) {
        targetNode.children.unshift(node);
      } else {
        oldSiblings.splice(oldIndex, 0, node); // Put the node back to its original position
        this.messageService.error(this.translate.instant("bookmarks.error.maxFolder"));
      }
    }
    // SPEC: Otherwise, the moved node is a sibling of the node above
    else {
      const newParent = this.getParent(targetNode.id, this.dataSource.data);
      const newSiblings = newParent.children;
      const newIndex = newSiblings.findIndex((sibling: TreeNode) => sibling.id === targetNode.id);
      if (this.canMove(node, newParent)) {
        newSiblings.splice(newIndex + 1, 0, node);
      } else {
        oldSiblings.splice(oldIndex, 0, node); // Put the node back to its original position
        this.messageService.error(this.translate.instant("bookmarks.error.maxFolder"));
      }
    }

    // Refresh display
    this.refreshData(this.dataSource.data); // To improve fluidity we don't wait for updateTree to refreshData
    return this.updateTree(oldTree, this.dataSource.data);
  }

  private getExportFolders(nodes: TreeNode[]): TreeNode[] {
    return this.flattenTree(nodes).filter((node: TreeNode) => node.children && node.id !== "root");
  }

  private createImportBookmark(tree: TreeNode, bookmark: Bookmark): Bookmark {
    // SPEC: we need to change the bookmark id to match with the current publication revision
    const newId = `bookmarks_${uuidv4()}__${this.store.publicationRevision}`;
    // change the bookmark id in tree to import
    const bookmarkNode = this.flattenTree([tree]).find(node => node.id === bookmark._id);
    bookmarkNode.id = newId;
    // change the bookmark id
    bookmark._id = newId;
    // set the minRevision for matching with the current publication revision
    bookmark.minRevision = this.store.publicationRevision;
    delete bookmark._rev;
    delete bookmark.maxRevision;
    return bookmark;
  }

  private removeFromTree(bookmarkId: string, node: TreeNode): void {
    const parent = this.getParent(bookmarkId, [node]);
    const index = parent.children.map((child: TreeNode) => child.id).indexOf(bookmarkId);
    parent.children.splice(index, 1);
  }

  private addTree(treeNode: TreeNode): Promise<boolean> {
    const oldTree = cloneDeep(this.dataSource.data);
    this.getExportFolders([treeNode]).forEach((node: TreeNode) => {
      node.id = "folder_" + uuidv4();
    });
    const root = this.getNodeById("root");
    if (treeNode.id === "root") {
      root.children = root.children.concat(treeNode.children);
    } else {
      root.children.push(treeNode);
    }

    return this.updateTree(oldTree, this.dataSource.data);
  }

  /**
   * get the list of bookmarks in a folder
   */
  private getBookmarkInside(folderId: string, bookmarkList: string[] = []): string[] {
    const folder = this.getNodeById(folderId);
    folder.children?.forEach((child: TreeNode) => {
      if (child.children) {
        // it's a folder and we want to get bookmarks inside
        return this.getBookmarkInside(child.id, bookmarkList);
      } else {
        // its a bookmark
        bookmarkList.push(child.id);
      }
    });
    return bookmarkList;
  }

  private buildTreeFlattener(): MatTreeFlattener<TreeNode, FlatTreeNode> {
    return new MatTreeFlattener(
      (node: TreeNode, level: number) => ({
        id: node.id,
        name: node.name,
        expandable: !!node.children,
        level,
        numChildren: node.children?.length || 0
      }),
      (node: FlatTreeNode) => node.level,
      (node: FlatTreeNode) => node.expandable,
      (node: TreeNode) => node.children
    );
  }

  private buildTreeControl(): FlatTreeControl<FlatTreeNode> {
    return new FlatTreeControl<FlatTreeNode>(
      (node: FlatTreeNode) => node.level,
      (node: FlatTreeNode) => node.expandable
    );
  }

  private flattenTree(nodes: TreeNode[], expandedFolders?: string[]): TreeNode[] {
    return nodes.reduce(
      (flatTree: TreeNode[], node: TreeNode) =>
        node.children && (!expandedFolders || expandedFolders.includes(node.id))
          ? flatTree.concat(node, this.flattenTree(node.children, expandedFolders))
          : flatTree.concat(node),
      []
    );
  }

  private getNodeAtIndex(nodes: TreeNode[], index: number): TreeNode {
    const flatTree = this.flattenTree(nodes, this.expansionModel.selected);
    return flatTree[index];
  }

  /**
   * Check wether a node (bookmark or folder) can be moved into the given folder according to business rules.
   * Current business rules are:
   * - Folders can be nested up to 5 levels of depth
   */
  private canMove(node: TreeNode, newParent: TreeNode): boolean {
    const newLocationDepth = this.treeControl.dataNodes.find(n => n.id === newParent.id).level + 1;
    const maxDepth = this.getMaxDepth(node);
    return newLocationDepth + maxDepth <= 5;
  }

  private getNodeById(nodeId: string): TreeNode {
    return this.flattenTree(this.dataSource.data).find(node => node.id === nodeId);
  }

  private createTree(): TreeNode[] {
    return [
      {
        id: "root",
        name: "Bookmarks",
        children: []
      }
    ];
  }

  private addOrphans(tree: TreeNode[]): Promise<TreeNode[]> {
    return this.bookmarkService.getBookmarks().then((bookmarks: Bookmark[]) => {
      const elementInTree = this.flattenTree(tree).map((node: TreeNode) => node.id);
      const dmcInTree = bookmarks
        .filter((bookmark: Bookmark) => elementInTree.includes(bookmark._id))
        .map((bookmark: Bookmark) => bookmark.dmc);
      const orphans = bookmarks
        .filter(
          (bookmark: Bookmark) =>
            !elementInTree.includes(bookmark._id) && !dmcInTree.includes(bookmark.dmc)
        )
        .map((orphan: Bookmark) => ({ id: orphan._id }));
      // SPEC: we want orphans before other bookmarks and folders
      tree[0].children = orphans.concat(tree[0].children);
      return tree;
    });
  }

  private refreshData(nodes: TreeNode[]): void {
    this.dataSource.data = nodes;

    this.expansionModel.select("root"); // We want the root folder to be expanded by default
    this.expansionModel.selected.forEach((id: string) => {
      const nodeToExpand = this.treeControl.dataNodes.find((node: FlatTreeNode) => node.id === id);
      this.treeControl.expand(nodeToExpand);
    });
  }
}
