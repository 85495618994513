import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  OnDestroy
} from "@angular/core";
import { ApplicabilityService, ViewerMessageService, Store } from "@viewer/core";
import { FormControl } from "@angular/forms";
import { Observable, startWith, map, Subscription } from "rxjs";
import { action } from "mobx-angular";
import { MatDialogRef } from "@angular/material/dialog";
import { UserCriterias } from "@viewer/core/applicability/applicability.service";
import { TranslateService } from "@ngx-translate/core";
import { makeObservable, runInAction } from "mobx";
import { MatSelectSearchComponent } from "ngx-mat-select-search";
import { VersionMappingPipe } from "@viewer/core/pipe/version-mapping.pipe";
import { ApplicabilityFleet, SerialNumber } from "@orion2/models/applicability.models";
import { MatOption } from "@angular/material/core";

@Component({
  selector: "o-applic-modal",
  templateUrl: "./applic-modal.component.html",
  styleUrls: ["./applic-modal.component.scss"]
})
export class ApplicModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("versionSearchComponent")
  private versionSearchComponent: MatSelectSearchComponent;

  @ViewChild("serialNumberSearchComponent")
  private serialNumberSearchComponent: MatSelectSearchComponent;

  public versions: string[];
  public serialNumbers: SerialNumber[];

  public selectedVersion: string;
  public selectedSerialNumber: string;
  public selectedModel: string;

  public isLoading = false;

  public showNotApplicable = true;
  public isVersionSelectionDisabled = false;
  public isFilterOn: boolean;

  public isUserSelectingVersion = false;
  public isUserSelectingSerialNumber = false;

  public versionController = new FormControl("");
  public serialNumberController = new FormControl("");

  public filteredVersions: Observable<string[]>;
  public filteredSerialNumbers: Observable<SerialNumber[]>;

  private subscriptions: Subscription;
  private versionMappingPipe: VersionMappingPipe;

  private versionMapping = new Map<string, string>();

  constructor(
    public store: Store,
    private applicabilityService: ApplicabilityService,
    private messageService: ViewerMessageService,
    private dialog: MatDialogRef<ApplicModalComponent>,
    private translate: TranslateService,
    private cd: ChangeDetectorRef
  ) {
    this.versionMappingPipe = new VersionMappingPipe(store);
    makeObservable(this, {
      toggleShowNotApplicable: action
    });
  }

  public ngOnInit(): void {
    this.initFilter();

    this.filteredVersions = this.versionController.valueChanges.pipe(
      startWith(""),
      map((search: string) => this.searchVersion(search))
    );

    this.filteredSerialNumbers = this.serialNumberController.valueChanges.pipe(
      startWith(""),
      map((search: string) => this.searchSerialNumber(search))
    );

    this.subscriptions = new Subscription();
  }

  public ngAfterViewInit() {
    /*serial value change the header title
    We catch the mat-select-search and listen the openedChange*/
    const onSerialNumberSelection =
      this.serialNumberSearchComponent.matSelect.openedChange.subscribe((isOpened: boolean) => {
        this.isUserSelectingSerialNumber = isOpened;
      });

    const onVersionSelection = this.versionSearchComponent.matSelect.openedChange.subscribe(
      (isOpened: boolean) => {
        this.isUserSelectingVersion = isOpened;
      }
    );

    this.subscriptions.add(onSerialNumberSelection);
    this.subscriptions.add(onVersionSelection);
  }

  public initFilter(): void {
    const fleet: ApplicabilityFleet = this.applicabilityService.initFleet();

    this.serialNumbers = fleet.serialno || [];
    this.versions = fleet.version || [];

    this.isFilterOn = !!this.applicabilityService.userSelectedCriterias.filterOn;
    this.selectedModel = this.applicabilityService.userSelectedCriterias.model;
    if (fleet.model?.length === 1) {
      this.selectedModel = fleet.model[0];
    }

    this.selectedSerialNumber = this.applicabilityService.userSelectedCriterias.serialno;

    if (this.versions.length === 1) {
      this.selectedVersion = fleet.version[0];
      this.isVersionSelectionDisabled = true;
    } else {
      this.selectedVersion = this.applicabilityService.userSelectedCriterias.version;
    }

    this.versionMapping = new Map<string, string>(
      this.versions.map((version: string) => [
        version,
        this.versionMappingPipe.transform(version, this.selectedModel)
      ])
    );

    this.showNotApplicable = !!this.applicabilityService.userSelectedCriterias.showNotApplicable;
    runInAction(() => {
      this.store.showNotApplicable = this.showNotApplicable;
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * In the serial numbers select we want to display only the sn which start with
   * the string the user has typed
   *
   * @param testedSerialno
   */
  public filterSerialno(testedSerialno: string, selectedVersion?: string): SerialNumber[] {
    return selectedVersion
      ? this.serialNumbers.filter(
          (serialno: SerialNumber) =>
            serialno.label.indexOf(testedSerialno) >= 0 &&
            serialno.version.indexOf(selectedVersion) >= 0
        )
      : this.serialNumbers.filter(serialno => serialno.label.indexOf(testedSerialno) >= 0);
  }

  /**
   * When the user selects a serial we can deduce the version from it
   * We do it thanks to the fleet
   */
  public setVersionFromSerial(): void {
    if (this.selectedSerialNumber) {
      this.selectedVersion = this.applicabilityService.getVersionFromSerial(
        this.selectedSerialNumber
      );
      // Manually update highlighted option
      this.versionSearchComponent.matSelect.options
        .map((option: MatOption, index: number) => ({ option, index }))
        .filter(({ option }) => option.value === this.selectedVersion)
        .forEach(({ index }) =>
          this.versionSearchComponent.matSelect._keyManager.setActiveItem(index)
        );
    }
  }

  public close(): void {
    this.dialog.close();
  }

  /* called when clic on serial select or window resize or oriantation change
  we get the filter pop-up position and size
  and put the overlay mat select search for serial number just below the hearder of filter pop-up */
  public resizeSerialPanel(event): void {
    //preventDefault and stopPropagation cancel the default behavior of mat-select-search
    event.preventDefault();
    event.stopImmediatePropagation();

    const bound = document
      .querySelector(".applic-filter-dialog-container") // select of filter pop-up
      .getBoundingClientRect();

    const headerHeight = 50;

    // Get the cdk-panel from the mat-select
    const element = document.querySelector(".mat-select-panel")?.parentElement?.parentElement;

    if (element) {
      element.setAttribute(
        "style",
        "width:" +
          (bound.width - 32) + // 32px for cancel recalc of width
          "px;" +
          "height:" +
          (bound.height - headerHeight) +
          "px;" +
          "top:" +
          (bound.top + headerHeight) +
          "px;" +
          "bottom:" +
          bound.bottom +
          "px;" +
          "left:" +
          bound.left +
          "px;" +
          "right:" +
          bound.right +
          "px;" +
          "min-width:" +
          (bound.width - 32) +
          "px;"
      );
    }

    // we use it for ios device. More check and test for prove it's improves the fludity on orientation change.
    this.cd.markForCheck();
  }

  /**
   * If the user selects a new model or version, we want to reset the serial
   */
  public resetSerialNumber(): void {
    this.selectedSerialNumber = "";
    // Triggers the serial number form control observer to reset the serial number choices
    this.serialNumberController.setValue("");
  }

  /**
   * Launch the process to find all the applicable md5 in function of the criterias selection
   */
  public filter(): void {
    this.selectedSerialNumber ??= "";
    this.selectedVersion ??= "";

    const criterias: UserCriterias = {
      filterOn: this.isFilterOn,
      model: this.selectedModel,
      version: this.selectedVersion,
      serialno: this.selectedSerialNumber,
      showNotApplicable: this.showNotApplicable
    };

    try {
      this.isLoading = true;

      if (!criterias.version && !criterias.serialno) {
        // We want to keep the current filterOn value
        this.applicabilityService.reset(criterias.filterOn);
      } else {
        this.applicabilityService.filter(criterias);
      }
      this.isLoading = false;
      this.dialog.close();
      if (this.isFilterOn) {
        this.messageService.success(
          `${this.translate.instant("applicability.message.applied")} ${
            this.selectedVersion ? this.selectedModel : "All"
          } ${this.selectedVersion || ""} ${this.selectedSerialNumber}`
        );
      } else {
        this.messageService.warning(this.translate.instant("applicability.message.disabled"));
      }
    } catch (e) {
      this.messageService.error(this.translate.instant("applicability.message.error"));
      console.error(e);
    }
  }

  /**
   * The user can choose if we want to see the unapplicable content with particular style
   * or simply hide it
   */
  public toggleShowNotApplicable() {
    this.store.showNotApplicable = this.showNotApplicable;
  }

  private searchVersion(search: string): string[] {
    return [...this.versionMapping.entries()]
      .filter(([_, mappedVersion]) => mappedVersion.includes(search))
      .map(([version, _]) => version);
  }

  private searchSerialNumber(search: string): SerialNumber[] {
    if (search) {
      return this.filterSerialno(search, this.selectedVersion);
    }
    return this.selectedVersion
      ? this.applicabilityService.getAllSNFromVersion(this.selectedVersion)
      : this.serialNumbers;
  }
}
