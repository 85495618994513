import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@viewer/core/state/store";

@Pipe({
  name: "pubLang"
})
export class PubLangPipe implements PipeTransform {
  private static cache: Object = {};
  public langAvailable = ["en", "fr", "es", "de"];

  constructor(private store: Store) {}

  translate(lang: string, key: string): string {
    if (!lang || !this.langAvailable.includes(lang)) {
      lang = "en";
    }

    if (!PubLangPipe.cache.hasOwnProperty(lang)) {
      PubLangPipe.cache[lang] = {};
    }

    // Load in memory if don't exist
    if (!PubLangPipe.cache[lang].hasOwnProperty(key)) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      PubLangPipe.cache[lang][key] = require(`../../../assets/i18n/${lang}.json`)[key];
    }

    return PubLangPipe.cache[lang][key];
  }

  transform(key: string): string {
    if (this.store.pubInfo) {
      const pubLang = this.store.pubInfo.lang;
      return this.translate(pubLang, key);
    } else {
      return "";
    }
  }
}
