import { Injectable } from "@angular/core";
import { ParamMap } from "@angular/router";
import { MsmFormatter } from "@orion2/msm-formatter/index";
import { TaskNamespace } from "@orion2/msm-formatter/types/MsmTask";
import { SearchResult } from "@viewer/core/search/searchModel";
import { Store } from "@viewer/core/state/store";
import { PrintTaskModel } from "@viewer/pdf-maker/print-task-model";
import { createPdf } from "pdfmake/build/pdfmake";
import { pdfMake } from "pdfmake/build/vfs_fonts";
import cloneDeep from "fast-copy";
import { PubLangPipe } from "@viewer/core/lang/pubLang.pipe";
import { Content, ContentColumns, ContentTable, ContentText } from "pdfmake/interfaces";
import { convertRevLabel } from "@orion2/utils/functions.utils";
import { enrichDocumentation } from "@viewer/shared-module/helper.utils";

@Injectable({
  providedIn: "root"
})
export class PdfMakerService {
  public pdfModel = new PrintTaskModel()._pdfModel;
  private pubLangPipe = new PubLangPipe(this.store);
  private fontSizeCardTitle = 10;
  private revTask = "";

  private setAllTaskCard = [
    this.setZones,
    this.setLimitType,
    this.setInterval,
    this.setDesc,
    this.setDocumentation,
    this.setMpnPn
  ];

  constructor(public store: Store) {}

  public createPdf(res: SearchResult[], queryParamMap: ParamMap): Promise<number[]> {
    this.pdfModel.content = [];

    this.setParentTitle(queryParamMap);

    res.forEach((task: SearchResult) => {
      this.addTaskCard(task);
    });

    this.setFooter();
    this.setSignFooter();

    const pdfDocGenerator = createPdf(this.pdfModel, undefined, undefined, pdfMake.vfs);

    return new Promise<number[]>(resolve => {
      {
        pdfDocGenerator.getBuffer((buffer: Buffer) => {
          resolve(Array.from(buffer));
        });
      }
    });
  }

  public setParentTitle(params: ParamMap): void {
    const tilte = params
      ? params.keys
          .map(
            k =>
              "[" +
              k +
              ": " +
              JSON.parse(params.getAll(k)[0])
                .map(p => p)
                .join(" ; ") +
              "]"
          )
          .join("  ")
      : "Default";

    this.pdfModel.content.push({
      text:
        "Maintenance tasks" +
        (this.store.searchInput ? " [search: " + this.store.searchInput + "]" : "") +
        (tilte ? " filtered by " + tilte : ""),
      fontSize: 12,
      color: "#02205b",
      bold: true,
      margin: [5, 5, 10, 5]
    });

    this.pdfModel.content.push({
      text: this.pubLangPipe.transform("changemark.label"),
      fontSize: 10,
      color: "black",
      background: PrintTaskModel.greenColor,
      margin: [5, 5, 5, 20]
    });
  }

  /**
   * This function add a card for a SearchResult in our pdf
   */
  private addTaskCard(data: SearchResult): void {
    this.revTask = data.revision;
    const task = MsmFormatter.unserialize(data.task, enrichDocumentation.bind(this));
    // SPEC: Deleted task may return undefined limitTypeValue
    const limitTypeValue: string = task.interval?.[0].limitTypeValue;
    const ponctual = limitTypeValue === "PO" || limitTypeValue === "OC";
    const card = PrintTaskModel.card(ponctual);
    (card as ContentText).decoration = this.revTask === "Deleted" ? "lineThrough" : undefined;

    const bodyCard = PrintTaskModel.bodyCard();
    bodyCard[0][0][0].push(this.createBodyCard(data, task));
    (card as ContentTable).table.body = bodyCard;

    this.pdfModel.content.push(cloneDeep(card));
  }

  /**
   * This function create the bodyCard for Tasks list PDF
   *
   * @private
   * @param _task
   * @returns return the bodyCard we created.
   * @memberof PdfMakerService
   */
  private createBodyCard(data: SearchResult, task: TaskNamespace.MsmTask): Content[] {
    const body: Content[] = [];

    body.push(this.getTitle(task, task.changed?.title));
    body.push(this.getSubTitle(task));

    this.setAllTaskCard.forEach((func: Function) => body.push(func(task)));

    body.push(this.setFrom(data.shortTitle, data.reference, task.changed?.from));

    return [body];
  }

  private setZones(task: TaskNamespace.MsmTask): ContentColumns[] {
    return [
      PrintTaskModel.bodyTable("Zone(s)", [
        [
          {
            text: task.preliminary?.zones.join(",") || "-",
            fillColor: task.preliminary?.changed ? PrintTaskModel.greenColor : ""
          }
        ]
      ])
    ];
  }

  private setLimitType(task: TaskNamespace.MsmTask): ContentColumns[] {
    return [
      PrintTaskModel.bodyTable(
        "Limit Type",
        [
          [
            {
              text: task.limitType || "-",
              fillColor: task.changed?.limitType ? PrintTaskModel.greenColor : ""
            }
          ]
        ],
        PrintTaskModel.redColor
      )
    ];
  }

  private setDesc(task: TaskNamespace.MsmTask): ContentColumns[] {
    return [
      PrintTaskModel.bodyTable(
        "Description",
        task.description && task.description?.length !== 0
          ? task.description.map((desc: TaskNamespace.Description) => [
              {
                text: desc.value || "",
                fillColor: desc.changed ? PrintTaskModel.greenColor : ""
              }
            ])
          : [[""]]
      )
    ];
  }

  private setDocumentation(task: TaskNamespace.MsmTask): ContentColumns[] {
    return [
      PrintTaskModel.bodyTable(
        "Documentation",
        task.documentation && task.documentation?.length !== 0
          ? task.documentation.map((doc: TaskNamespace.Documentation) => [
              {
                text:
                  (doc.shortDMC ? doc.shortDMC + "\n" : "") + (doc.loc ? doc.loc.join("\n") : ""),
                fillColor: doc.changed ? PrintTaskModel.greenColor : ""
              }
            ])
          : [[""]],
        PrintTaskModel.accentColor
      )
    ];
  }

  private setMpnPn(task: TaskNamespace.MsmTask): ContentColumns[] {
    return [
      PrintTaskModel.bodyTable(
        "MP/N (P/N)",
        task.mpn_pn && task.mpn_pn?.length !== 0
          ? task.mpn_pn.map((mp: TaskNamespace.MpnPn) => [
              {
                text: mp.pn ? `${mp.mpn} (${mp.pn})` : `${mp.mpn}-`,
                fillColor: mp.changed ? PrintTaskModel.greenColor : ""
              }
            ])
          : [[""]]
      )
    ];
  }

  private setFrom(shortTitle: string, reference: string, isChanged: boolean): ContentColumns[] {
    const from = [
      [
        {
          text: this.revTask !== "Deleted" ? shortTitle : "",
          fillColor: isChanged ? PrintTaskModel.greenColor : ""
        }
      ],
      [
        {
          text: reference,
          color: "gray",
          fillColor: isChanged ? PrintTaskModel.greenColor : ""
        }
      ]
    ];

    return [PrintTaskModel.bodyTable("From", from)];
  }

  private setInterval(task: TaskNamespace.MsmTask): ContentColumns[] {
    return [
      PrintTaskModel.bodyTable(
        "Interval (Margin)",
        task.interval && task.interval?.length !== 0
          ? task.interval.map((int, index) => [
              PrintTaskModel.column(
                [
                  {
                    text: int.limitTypeValue ? int.limitTypeValue + "" : "",
                    width: 15
                  },
                  { text: int.sampling ? int.sampling + "" : "", width: 10 },
                  { text: int.operator ? int.operator + "" : "", width: 10 },
                  { text: int.val ? int.val + "" : "" },
                  {
                    text: int.unit !== "-" && int.unit ? int.unit + "" : "",
                    margin: [10, 0, 0, 0]
                  },
                  {
                    text: int?.temporaryLimit || "",
                    width: 20
                  },
                  {
                    text: int?.freqStart || "",
                    width: 42
                  }
                ],
                PrintTaskModel.redColor,
                null,
                int.changed ? PrintTaskModel.greenColor : ""
              ),
              PrintTaskModel.column(
                [
                  {
                    text: `( ${task.margin[index].val || ""} ${
                      (task.margin[index].unit !== "-" && task.margin[index].unit) || ""
                    } )`,
                    margin: [20, 0, 0, 0]
                  }
                ],
                "black",
                null,
                task.margin[index].changed ? PrintTaskModel.greenColor : ""
              )
            ])
          : [[""]]
      )
    ];
  }

  private getRevisionMark(task: TaskNamespace.MsmTask): ContentText {
    const revisionText = {
      text: "",
      color: "black",
      width: 25
    };

    if (task.status) {
      switch (task.status.toLowerCase()) {
        case "new": {
          revisionText.text = "N";
          revisionText.color = PrintTaskModel.primaryColor;
          break;
        }
        case "changed": {
          revisionText.text = "R";
          revisionText.color = "#6faf41";
          break;
        }
        case "deleted": {
          revisionText.text = "D";
          revisionText.color = PrintTaskModel.redColor;
          break;
        }
        default: {
          break;
        }
      }
    } else {
      revisionText.text = "D";
      revisionText.color = PrintTaskModel.redColor;
    }

    return revisionText;
  }

  private getTitle(task: TaskNamespace.MsmTask, isChanged: boolean): Content {
    return PrintTaskModel.column([
      {
        text: task.title || "",
        fontSize: this.fontSizeCardTitle,
        background: isChanged ? PrintTaskModel.greenColor : ""
      },
      task.status !== "unchanged" || this.revTask === "Deleted"
        ? this.getRevisionMark(task)
        : {
            text: "",
            width: 25
          }
    ]);
  }

  private getSubTitle(task: TaskNamespace.MsmTask): Content {
    return PrintTaskModel.column(
      [
        {
          text: task.id ? task.id : "",
          color: "gray"
        },
        {
          text: task.versions !== undefined ? task.versions : "",
          width: 25
        }
      ],
      "gray"
    );
  }

  private setFooter(): void {
    this.pdfModel.footer = PrintTaskModel.column(
      [
        {
          text: `Printed from Orion ${this.store.pubInfo.verbatimText} Rev.${convertRevLabel(
            this.store.pubInfo.revision
          )} Rev Date : ${this.store.pubInfo.revisionDate}`,
          alignment: "left",
          margin: [45, 0, 0, 0]
        },
        {
          text: `Printed by ${this.store.user.userName} on ${new Date()
            .toISOString()
            .slice(0, 10)}`,
          alignment: "right",
          margin: [0, 0, 50, 0]
        }
      ],
      "gray",
      7
    );
  }

  private setSignFooter(): void {
    this.pdfModel.content.push({
      table: {
        widths: ["*", "*", "*", "*"],
        heights: [undefined, 60],
        body: [
          ["Mechanist", "Date", "Inspector", "Date"],
          ["Name :", "Signature :", "Name :", "Signature :"]
        ]
      },
      margin: [5, 2, 10, 20],
      fontSize: 7,
      unbreakable: true,
      // hLineWidth and vLineWidth take function which return a value in "pt" for each horizontal and vertical lines
      layout: {
        hLineColor: "black",
        vLineColor: "black",
        hLineWidth: () => 0.75,
        vLineWidth: () => 0.75
      }
    });
  }
}
