import { Component, OnInit } from "@angular/core";
import { News } from "@orion2/models/tocitem.models";
import { NewsUtilsService } from "@viewer/news/services/news-utils.service";
import { combineLatest, map, Observable, take } from "rxjs";

enum NewsTab {
  APPLICATION,
  PUBLICATION
}

@Component({
  selector: "o-news-modal",
  templateUrl: "./news-modal.component.html",
  styleUrls: ["./news-modal.component.scss"]
})
export class NewsModalComponent implements OnInit {
  public applicationNews = this.newsUtilsService.applicationNews;
  public publicationNews = this.newsUtilsService.publicationNews;
  public selectedTab: Observable<number>;

  constructor(private newsUtilsService: NewsUtilsService) {}

  ngOnInit(): void {
    this.selectedTab = this.getDefaultTab();
  }

  /**
   * Define the default tab for the News Modal.
   * SPEC: The default tab should be "Publication" EXCEPT if:
   * - There is no publication news yet (because there is none on the pub, or because user is still in the store)
   * - Or, the user has at least one important notification unread
   * - Or, the user has unread notification but no unread publication news
   */
  private getDefaultTab(): Observable<number> {
    return combineLatest([this.applicationNews, this.publicationNews]).pipe(
      map(([appNews, pubNews]) => {
        const unreadAppCount = this.newsUtilsService.countUnreadNews(appNews);
        const unreadPubCount = this.newsUtilsService.countUnreadNews(pubNews);
        const shouldOpenAppTab =
          !pubNews.length ||
          appNews.some((news: News) => !news.read && news.isImportant) ||
          (unreadAppCount && !unreadPubCount);

        return shouldOpenAppTab ? NewsTab.APPLICATION : NewsTab.PUBLICATION;
      }),
      take(1)
    );
  }
}
