import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { LocalStorageBackend } from "@openid/appauth";
import {
  DisclaimerComponent,
  DisclaimerType
} from "@viewer/help-module/disclaimer/disclaimer.component";
import { ConfService } from "@viewer/core";

@Injectable()
export class AuthGuard implements CanActivate {
  private storage = new LocalStorageBackend();

  constructor(private dialog: MatDialog, private confService: ConfService) {}

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
    const version = this.confService.disclaimersVersions["termsOfUse"];
    return this.getTermOfUseAgreement(version).then(response => {
      // If ToU aren't signed, display popup
      if (!response && this.dialog.openDialogs.length === 0) {
        const dialogRef = this.dialog.open(DisclaimerComponent, {
          disableClose: true,
          closeOnNavigation: false,
          panelClass: "disclaimer-dialog-container",
          data: {
            type: DisclaimerType.TERMS_OF_USE,
            consult: false
          }
        });
        return dialogRef
          .afterClosed()
          .toPromise()
          .then(() => this.setTermOfUseAgreement(version).then(() => true));
      }
      return true;
    });
  }

  /**
   * Getter of localStorage for Terms of Use
   *
   * @param version
   */
  getTermOfUseAgreement(version: string): Promise<boolean> {
    return this.storage.getItem(`term_of_use_v${version}_agreement`).then(response => !!response);
  }

  /**
   * Setter of localStorage for Terms of Use
   * Always true because you can't refuse
   *
   * @param version
   */
  setTermOfUseAgreement(version: string): Promise<void> {
    return this.storage.setItem(`term_of_use_v${version}_agreement`, "true");
  }
}
