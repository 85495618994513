/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { LoadingModalComponent } from "@viewer/loading-modal/loading-modal.component";
import { CordovaService } from "libs/cordova/cordova.service";

@Injectable({
  providedIn: "root"
})
export class PlayerService {
  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private cordovaService: CordovaService
  ) {}

  /**
   * Method to ensure that the player is downloaded and ready to use
   *
   * @param version - URL of player zip
   * @param getPlayerZipBlob - Function that returns a Promise<Blob>. Function to retrieve zip as Blob
   * @param background - Boolean to display player download's dialog
   */
  public checkPlayer(
    version: string,
    getPlayerZipBlob: Function,
    background = false
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const playerSupposedPath = `DS_${version}`;
      (window as any).resolveLocalFileSystemURL(
        (window as any).cordova.file.dataDirectory,
        dir => {
          dir.getDirectory(
            playerSupposedPath,
            { create: false },
            () => resolve(),
            () => {
              let dialogRef: MatDialogRef<any>;
              // SPEC: Only display a dialog when needed
              if (!background) {
                dialogRef = this.dialog.open(LoadingModalComponent, {
                  data: this.translate.instant("download.player", {
                    version
                  }),
                  panelClass: "loading-dialog-container",
                  disableClose: true
                });
              }
              // TODO: Remove other versions of the player
              getPlayerZipBlob()
                .then((zipBlob: Blob) => {
                  const filePath = this.cordovaService.getFilePath(`${playerSupposedPath}.zip`);
                  return this.cordovaService.writeFile(filePath, zipBlob);
                })
                .then(() =>
                  this.cordovaService.unzipFile(
                    `${(window as any).cordova.file.dataDirectory}${playerSupposedPath}.zip`
                  )
                )
                .then(() =>
                  this.cordovaService.deleteFile(
                    (window as any).cordova.file.dataDirectory,
                    `${playerSupposedPath}.zip`
                  )
                )
                .then(() => {
                  if (!background) {
                    dialogRef.close();
                  }
                  resolve();
                })
                .catch(err => {
                  if (!background) {
                    dialogRef.close();
                  }
                  console.error(
                    "ENSURE PLAYER DOWNLOADED ERROR while downloading player",
                    version,
                    err
                  );
                  reject(err);
                });
            }
          );
        },
        err => {
          console.error("ENSURE PLAYER DOWNLOADED ERROR while retrieving folder", version, err);
          reject(err);
        }
      );
    });
  }

  /**
   * Method to return DS 3D Player source for iframe
   *
   * @param version - Version of player
   */
  public getPlayerSrc(version: string): string {
    const src = `${
      (window as any).cordova.file.dataDirectory
    }DS_${version}/DS/3DPlayHelper/3DPlaySyndication.html`;
    return (window as any).Ionic.WebView.convertFileSrc(src);
  }
}
