import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { StatusCodes } from "http-status-codes";
import {
  BehaviorSubject,
  interval,
  mergeMap,
  Observable,
  Subscription,
  takeWhile,
  tap
} from "rxjs";
import { BASEPUB_API_PING_INTERVAL, BASEPUB_API_PING_URL } from "./degraded-state.model";

@Injectable({
  providedIn: "root"
})
export class DegradedStateService implements OnDestroy {
  private blocked: boolean = false;

  public get isBlocked(): boolean {
    return this.blocked;
  }

  private state: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private pingSubscription: Subscription;

  constructor(private http: HttpClient) {}

  public get degradedState(): Observable<boolean> {
    return this.state.asObservable();
  }

  public get isDegraded(): boolean {
    return this.state.value;
  }

  public ngOnDestroy(): void {
    this.pingSubscription?.unsubscribe();
  }

  activate() {
    if (!this.state.value) {
      this.state.next(true);
      this.pingAPIs();
    }
  }

  deactivate() {
    if (this.state.value) {
      this.state.next(false);
      this.pingSubscription?.unsubscribe();
    }
  }

  block(value: boolean) {
    this.blocked = value;
  }

  private pingAPIs() {
    this.pingSubscription = interval(BASEPUB_API_PING_INTERVAL)
      .pipe(
        mergeMap(() => this.http.get<{ status?: StatusCodes }>(BASEPUB_API_PING_URL)),
        tap(result => {
          if (result?.status === StatusCodes.OK) {
            this.deactivate();
          }
        }),
        takeWhile(() => this.isDegraded)
      )
      .subscribe();
  }
}
