import { Injectable } from "@angular/core";
import { environment } from "@viewer-env/environment";
import { Store, PouchService } from "@viewer/core";
import { MatDialog } from "@angular/material/dialog";
import { TransfertTiDialogComponent } from "@viewer/transfert-ti-dialog/transfert-ti-dialog.component";
import { SynchroModalComponent } from "@viewer/synchro-modal/synchro-modal.component";

@Injectable()
export class TransfertTiService {
  private publicationId: string;
  private samAccountName: string;
  private promisePending: Promise<boolean>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private promiseResolved: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private promiseReject: any;
  private occurrenceCode: string;

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private pouchService: PouchService
  ) {}

  public checkUserHasPending(pub): Promise<boolean> {
    this.occurrenceCode = pub.occurrenceCode;
    this.samAccountName = this.store.user.samAccountName;
    this.publicationId = pub.packageId;
    this.resetPromise();
    return this.hasDeclinedTransfer().then(
      (hasDeclined: boolean) =>
        !hasDeclined &&
        environment.authService
          .checkUserHasPending(this.occurrenceCode)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((response: any) => {
            if (response.total > 0) {
              this.openPopup().then((transfer: boolean) => {
                response.private = transfer ? response.private : 0;
                return Promise.all([
                  transfer ? Promise.resolve() : this.setDeclinedTransfer(),
                  this.synchronize(
                    environment.authService.transferTocItemsFromPending(
                      this.occurrenceCode,
                      transfer
                    )
                  )
                ])
                  .then(() => {
                    this.promiseResolved(true);
                  })
                  .catch(err => {
                    console.error(err);
                    this.promiseReject(false);
                  });
              });
              return this.promisePending;
            } else {
              this.promiseResolved = undefined;
              this.promiseReject = undefined;
              this.promisePending = undefined;
            }
            return response;
          })
          .catch(error => {
            console.error(`Error while checking if user has pending: ${error}`);
            return false;
          })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public synchronize(transfertProm: Promise<boolean>): Promise<any> {
    const dialogSynchro = this.dialog.open(SynchroModalComponent, {
      panelClass: "settings-dialog-container",
      disableClose: true,
      data: transfertProm
    });
    return dialogSynchro.afterClosed().toPromise();
  }

  /**
   * Check if user reject document is created for the current publication
   * Return the document or undefined if not exist.
   */
  private hasDeclinedTransfer(): Promise<boolean> {
    return this.pouchService.userCaller.getHasUserDeclineTransferForDB(this.occurrenceCode);
  }

  private setDeclinedTransfer(): Promise<void> {
    return this.pouchService.userCaller.setUserDeclineTransferForDB(this.occurrenceCode);
  }

  private resetPromise() {
    this.promiseResolved = undefined;
    this.promiseReject = undefined;
    this.promisePending = new Promise<boolean>((resolve, reject) => {
      this.promiseResolved = resolve;
      this.promiseReject = reject;
    });
  }

  private openPopup(): Promise<boolean> {
    const dialogRef = this.dialog.open(TransfertTiDialogComponent, {
      panelClass: "transfer-ti-dialog-container",
      disableClose: true
    });
    return dialogRef.afterClosed().toPromise();
  }
}
