import { AuthorizationServiceConfiguration } from "@openid/appauth/built/authorization_service_configuration";
import { AppAuthError } from "@openid/appauth/built/errors";
import { BasicQueryStringUtils, QueryStringUtils } from "@openid/appauth/built/query_string_utils";
import { RevokeTokenRequest } from "@openid/appauth/built/revoke_token_request";
import { TokenRequest } from "@openid/appauth/built/token_request";
import {
  TokenError,
  TokenErrorJson,
  TokenResponse,
  TokenResponseJson
} from "@openid/appauth/built/token_response";
import { FetchRequestor, Requestor } from "@openid/appauth/built/xhr";
import { TokenRequestHandler } from "@openid/appauth/built/token_request_handler";

/**
 * The default token request handler.
 */
export class ExtendedTokenRequestHandler implements TokenRequestHandler {
  _promise: Promise<TokenResponse>;
  _resolve: (value?: {} | PromiseLike<TokenResponseJson | TokenErrorJson>) => any;
  _reject: (reason?: any) => void;

  constructor(
    public readonly requestor: Requestor = new FetchRequestor(),
    public readonly utils: QueryStringUtils = new BasicQueryStringUtils()
  ) {
    this._promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  public get promise(): Promise<TokenResponse> {
    return this._promise;
  }

  resetPromise() {
    this._promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  public performRevokeTokenRequest(
    configuration: AuthorizationServiceConfiguration,
    request: RevokeTokenRequest
  ): Promise<boolean> {
    return this.requestor
      .xhr<boolean>({
        url: configuration.revocationEndpoint,
        method: "POST",
        // eslint-disable-next-line @typescript-eslint/naming-convention
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: this.utils.stringify(request.toStringMap())
      })
      .then(() => true);
  }

  performTokenRequest(
    configuration: AuthorizationServiceConfiguration,
    request: TokenRequest
  ): Promise<TokenResponse> {
    const fetch = this.requestor.xhr<TokenResponseJson | TokenErrorJson>({
      url: configuration.tokenEndpoint,
      method: "POST",
      dataType: "json", // adding implicit dataType
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      data: this.utils.stringify(request.toStringMap())
    });

    fetch
      .then((response: TokenResponseJson | TokenErrorJson) => {
        if (this.isTokenResponse(response)) {
          this._resolve(new TokenResponse(response));
        } else {
          this._reject(new AppAuthError(response.error, new TokenError(response)));
        }
      })
      // TODO DG rework the error message
      .catch(err => {
        this._reject({ code: 400, message: err });
      });
    return this._promise;
  }

  protected isTokenResponse(
    response: TokenResponseJson | TokenErrorJson
  ): response is TokenResponseJson {
    return (response as TokenErrorJson).error === undefined;
  }
}
