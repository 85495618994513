import {
  HttpErrorResponse,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { filter, of, tap } from "rxjs";
import { IS_CACHE_RESULT } from "../cache/cache.model";
import { DEGRADED_STATE_ERROR, isDegradedStateError } from "../errors/degraded-error.model";
import { BASEPUB_API_PING_URL } from "./degraded-state.model";
import { DegradedStateService } from "./degraded-state.service";

const URL_PATH_SEPARATOR = "/";

// All URL request paths which use basepub APIs
const basepubPaths: { [key: string]: boolean } = {
  oak: true,
  basepub: true,
  external: true
};

const basepubProductAPI = "basepub/api/user/products";

@Injectable()
export class DegradedStateInterceptor implements HttpInterceptor {
  constructor(private degradedStateService: DegradedStateService) {
    this.patchNativeFetch();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    this.checkIfBasepubBlocked(request.url);
    return next.handle(request).pipe(
      filter(event => event.type === HttpEventType.Response),
      tap({
        next: (response: HttpResponse<unknown>) => {
          if (
            this.hasToDeactivateDegradedState(request.url, response.headers?.has(IS_CACHE_RESULT))
          ) {
            this.degradedStateService.deactivate();
          }
        },
        error: (httpError: HttpErrorResponse) => {
          if (isDegradedStateError(httpError?.error)) {
            this.degradedStateService.activate();
          }
        }
      })
    );
  }

  /**
   * Native JS fetch method is used several times in the project (by OIDC provider for example),
   * so we have to listen it also
   */
  private patchNativeFetch() {
    const { fetch: originalFetch } = window;
    window.fetch = async (...args) => {
      let [resource, config] = args;
      this.checkIfBasepubBlocked(resource.toString());
      const response = await originalFetch(resource, config).then(result =>
        this.checkHttpResponse(resource.toString(), result)
      );
      return response;
    };
  }

  /** Checks HTTP responses. If this is DEGRADED_STATE tagged error, degraded state is activated. */
  private checkHttpResponse(requestURL: string, response: Response): Promise<Response> {
    if ((response.status < 200 || response.status >= 300) && this.hasJsonResponse(response)) {
      return response
        .clone()
        .json()
        .then(responseError => {
          if (isDegradedStateError(responseError)) {
            this.degradedStateService.activate();
          }
          return response;
        });
    } else if (this.degradedStateService.isDegraded && this.isBasepubRequest(requestURL)) {
      if (this.hasToDeactivateDegradedState(requestURL, response.headers.has(IS_CACHE_RESULT))) {
        this.degradedStateService.deactivate();
      }
    }
    return Promise.resolve(response);
  }

  private hasJsonResponse(response: Response): boolean {
    return response.headers.get("content-type")?.indexOf("application/json") !== -1;
  }

  private isBasepubRequest(url: string): boolean {
    const paths: string[] = url.split(URL_PATH_SEPARATOR);
    return paths.find(path => !!basepubPaths[path]) !== undefined;
  }

  private checkIfBasepubBlocked(requestUrl: string) {
    if (this.degradedStateService.isBlocked) {
      this.degradedStateService.activate();

      if (!requestUrl.endsWith(basepubProductAPI)) {
        throw DEGRADED_STATE_ERROR;
      }
    }
  }

  private hasToDeactivateDegradedState(requestURL: string, hasCacheHeader: boolean): boolean {
    return (
      this.degradedStateService.isDegraded &&
      this.isBasepubRequest(requestURL) &&
      requestURL !== BASEPUB_API_PING_URL &&
      !hasCacheHeader &&
      !this.degradedStateService.isBlocked
    );
  }
}
