import { UserManagementResponse } from "@orion2/auth/role.interfaces";

enum Roles {
  GUEST = "GUEST",
  USER = "USER",
  MANAGER = "MANAGER",
  ADMIN = "ADMIN",
  INTERNAL = "INTERNAL"
}

/**
 * @userManagementResponse JSON response from user role API
 * User object with rights for toc item creation and read
 */
export class User {
  // User info
  private _emailAddress: string;
  private _userName: string;
  private _siebelId: string;
  private _samAccountName: string;
  private _techpubRole: Roles = Roles.GUEST;
  // ORION roles specific actions
  private _makePrivateNote = false;
  private _makeCorpNote = false;
  private _makeInspection = false;
  private _makePreprint = false;
  private _makeBookmark = false;
  private _makePublicNote = false;
  private _makePym = false;
  // ORION actions specific actions
  private _makeOrdering = false;
  private _makeTechnicalEvent = false;

  constructor(userManagementResponse: UserManagementResponse) {
    this.parseRoleRequest(userManagementResponse);
  }

  get makePrivateNote(): boolean {
    return this._makePrivateNote;
  }

  get makeCorpNote(): boolean {
    return this._makeCorpNote;
  }

  get makeInspection(): boolean {
    return this._makeInspection;
  }

  get makePreprint(): boolean {
    return this._makePreprint;
  }

  get makeBookmark(): boolean {
    return this._makeBookmark;
  }

  get makeOrdering(): boolean {
    return this._makeOrdering;
  }

  get makePublicNote(): boolean {
    return this._makePublicNote;
  }

  get makeTechnicalEvent(): boolean {
    return this._makeTechnicalEvent;
  }

  get makePym(): boolean {
    return this._makePym;
  }

  get emailAddress(): string {
    return this._emailAddress;
  }

  get userName(): string {
    return this._userName;
  }

  get siebelId(): string {
    return this._siebelId;
  }

  get samAccountName(): string {
    return this._samAccountName;
  }

  public print(): void {
    console.log(`
    userName: ${this._userName}
    siebelId: ${this._siebelId}
    emailAddress: ${this._emailAddress}
    techpubRole: ${this._techpubRole}
    makePrivateNote: ${this._makePrivateNote}
    makeCorpNote: ${this._makeCorpNote}
    makeInspection: ${this._makeInspection}
    makePreprint: ${this._makePreprint}
    makeBookmark: ${this._makeBookmark}
    makeOrdering: ${this._makeOrdering}
    makePublicNote: ${this._makePublicNote}
    makeTechnicalEvent: ${this._makeTechnicalEvent}
    makePym: ${this._makePym}
    `);
  }

  private parseRoleRequest(userManagementResponse: UserManagementResponse): void {
    this._emailAddress = userManagementResponse?.emailAddress;
    this._siebelId =
      userManagementResponse?.userType === "INTERNAL"
        ? "INTERNAL"
        : userManagementResponse?.companyId;
    this._samAccountName = userManagementResponse?.adLogin;
    this._userName = `${userManagementResponse.lastName?.toUpperCase()} ${
      userManagementResponse?.firstName
    }`;

    if (this._siebelId === "INTERNAL") {
      this._techpubRole = Roles.INTERNAL;
    } else {
      for (const group of userManagementResponse?.adGroups) {
        // Set ORION roles
        if (this._techpubRole !== Roles.MANAGER && /TechpubUser-usr/g.test(group)) {
          this._techpubRole = Roles.USER;
        }
        if (/TechpubManager-usr/g.test(group)) {
          this._techpubRole = Roles.MANAGER;
        }

        // Set ORION actions
        if (/WebTEK-usr/g.test(group)) {
          this._makeTechnicalEvent = true;
        }
        if (/QuotationOnly-usr|FullAccess-usr/g.test(group)) {
          this._makeOrdering = true;
        }
      }
    }

    // Set actions depending on role
    switch (this._techpubRole) {
      case Roles.USER:
        this._makePrivateNote = true;
        this._makeInspection = true;
        this._makeBookmark = true;
        this._makePym = true;
        break;
      case Roles.MANAGER:
        this._makePrivateNote = true;
        this._makeCorpNote = true;
        this._makeInspection = true;
        this._makeBookmark = true;
        this._makePym = true;
        break;
      case Roles.ADMIN:
        this._makePrivateNote = true;
        this._makeCorpNote = true;
        this._makePublicNote = true;
        this._makeInspection = true;
        this._makePreprint = true;
        this._makeBookmark = true;
        this._makePym = true;
        break;
      case Roles.INTERNAL:
        this._makePrivateNote = true;
        this._makeInspection = true;
        this._makeBookmark = true;
        this._makePym = true;
        break;
      // SPEC: Default user is GUEST
      case Roles.GUEST:
      default:
        this._makePrivateNote = true;
        this._makeInspection = true;
        this._makeBookmark = true;
        break;
    }
  }
}
